<style>
  .chat_modal {
    height: calc(100vh - 127px);
  }
</style>
<div class="loaderr2" *ngIf="loader">
  <div class="lds-roller">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
<div class="wrap_us">
  <div class="conten_main conten_set">
    <div class="p-4">
      <h3 class="main_head">
        Dashboard
        <span style="margin-left: 1200px;">
          <!-- <button class="btn btn_theme " [routerLink]="['/analytics']">Analytics</button> &nbsp; -->
          <button class="btn btn_theme " [routerLink]="['/superadmin/pie-charts']">Analytics</button>
        </span>
      </h3>
      <div class="row row_sm">
        <div class="col-md-4 hand-cursor">
          <div class="dash_box1 status_unread" (click)="clcikCard('newUser')">
            <div class="img_icon">
              <img src="assets/img/users.png" alt="" class="img_r">
            </div>
            <h1>{{dashboardCount?.todayusercount}}</h1>
            <p>
              New Users
            </p>
          </div>
        </div>
        <div class="col-md-4 hand-cursor">
          <div class="dash_box1 status_open" (click)="clcikCard('cureentWeekUser')">
            <div class="img_icon">
              <img src="assets/img/users.png" alt="" class="img_r">
            </div>
            <h1>{{dashboardCount?.weekusercount}}</h1>
            <p>
              Current Week Users
            </p>
          </div>
        </div>
        <div class="col-md-4 hand-cursor">
          <div class="dash_box1 status_close" (click)="clcikCard('cureentMonthUser')">
            <div class="img_icon">
              <img src="assets/img/users.png" alt="" class="img_r">
            </div>
            <h1>{{dashboardCount?.monthusercount}}</h1>
            <p>
              Current Month Users
            </p>
          </div>
        </div>
        <div class="col-md-4 hand-cursor">
          <div class="dash_box1 status_unread" (click)="clcikCard('activeUser')">
            <div class="img_icon">
              <img src="assets/img/users.png" alt="" class="img_r">
            </div>
            <h1> {{dashboardCount?.totalactiveusercount}}</h1>
            <p>
              Active Users
            </p>
          </div>
        </div>
        <div class="col-md-4 hand-cursor">
          <div class="dash_box1 status_unread" (click)="clcikCard('deactiveUser')">
            <div class="img_icon">
              <img src="assets/img/users.png" alt="" class="img_r">
            </div>
            <h1>{{dashboardCount?.totaldeactiveusercount}}</h1>
            <p>
              Deactive Users
            </p>
          </div>
        </div>
        <div class="col-md-4 hand-cursor">
          <div class="dash_box1 status_unread" (click)="clcikCard('totalUser')">
            <div class="img_icon">
              <img src="assets/img/users.png" alt="" class="img_r">
            </div>
            <h1>{{dashboardCount?.totalusercount}}</h1>
            <p>
              Total Users
            </p>
          </div>
        </div>
      </div>
      <!-- <div class="mb-3">
        <div class="card_me">
          <div class="card_body">
            <h4> Whatsup Subscription </h4>
            <table class="table table-sm border_none">
              <tbody>
                  <tr>
                    <td style="width:90%;">New Subscription :</td>
                    <td scope="row" class="text-end">{{subscribeVal?.todaySubscribeusercount}}</td>
                  </tr>
                  <tr>
                    <td>Current Week's Subscription :</td>
                    <td scope="row" class="text-end">{{subscribeVal?.weekSubscribeusercount}}</td>
                  </tr>
                  <tr>
                    <td>Current Month's Subscription:</td>
                    <td scope="row" class="text-end">{{subscribeVal?.monthSubscribeusercount}}</td>
                  </tr>
                  <tr>
                    <td>Free Subscription:</td>
                    <td scope="row" class="text-end">{{subscribeVal?.totalfreeusercount}}</td>
                  </tr>
                  <tr>
                    <td>Total Subscription:</td>
                    <td scope="row" class="text-end">{{subscribeVal?.totalusercount}}</td>
                  </tr>
              </tbody>
            </table>            
          </div>
        </div>
      </div> -->

      <div class="mb-3">



        <div class="card_me">
          <div class="card_body">
            <div class="col-md-12" style="display: flex;justify-content: space-between;">
              <div class="mb-9">
                <h4> Whatsup Subscription </h4>
              </div>
              <div class="mb-3 ">
                <input type="text" name="subscriptionDateTimes" id="subscriptionRange" class="form-control wh_us arrow-cursor"/>
                <!-- <p id="startDate">Start Date:</p>
                  <p id="endDate">End Date:</p> -->
              </div>
            </div>
            <ul class="list-group">
              <li class="list-group-item d-flex justify-content-between align-items-center text-color"
                style="margin-bottom: 0px !important;">
                Paid Subscription : <span class="margin-right-light">{{subscriptionCounts?.Subscription}}</span>
                <!-- <span class="badge badge-primary badge-pill">14</span> -->
              </li>
              <!-- <li class="list-group-item d-flex justify-content-between align-items-center text-color"
                style="margin-bottom: 0px !important;">
                Current Week's Subscription : <span
                  class="margin-right-light">{{subscribeVal?.weekSubscribeusercount}}</span>
                <span class="badge badge-primary badge-pill">2</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center text-color"
                style="margin-bottom: 0px !important;">
                Current Month's Subscription : <span
                  class="margin-right-light">{{subscribeVal?.monthSubscribeusercount}}</span>
                <span class="badge badge-success">1</span>
              </li> -->
              <li class="list-group-item d-flex justify-content-between align-items-center text-color"
                style="margin-bottom: 0px !important;">
                Free Subscription : <span class="margin-right-light">{{subscriptionCounts?.free}}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center text-color">
                Total Subscription : <span class="margin-right-light">{{subscriptionCounts?.total}}</span>
              </li>
            </ul>                       
          </div>
        </div>
      </div>

      <div class="row">

        <div class="col-md-6">
          <div class="table_desi">
            <div class="col-md-12" style="display: flex;justify-content: space-between;">
              <div class="mb-9">
                <h4> Package Wise Users </h4>
              </div>
              <div class="mb-3 ">
                <input type="text" name="packageWishDateTimes" id="packageWishRange" class="form-control wh_us arrow-cursor" />
                <!-- <p id="startDate">Start Date:</p>
                  <p id="endDate">End Date:</p> -->

              </div>
            </div>
            <table class="table" id="PackageWiseUsersTable">
              <thead>
                <tr>
                  <th>Package Name</th>
                  <th>Renewed Count</th>
                  <th>Active Count</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>                       
          </div>
        </div>
        <div class="col-md-6">

          <div class="table_desi">
            <div class="col-md-12" style="display: flex;justify-content: space-between;">
              <div class="mb-9">
                <h4> Package Wise Expire Info </h4>
              </div>
              <!-- <div class="mb-3 ">
                <input type="text" name="packageExpireInfoDateTimes" id="packageExpireInfoRange"
                  class="form-control wh_us" />
                 <p id="startDate">Start Date:</p>
                  <p id="endDate">End Date:</p> 

              </div> -->
            </div>
            <table class="table" id="CountOfPackageexpier">
              <thead>
                <tr>
                  <th>Package Name</th>
                  <th>Today's Expire</th>
                  <th>Weekly Expire</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>           
          </div>
        </div>
      </div>
    </div>
  </div>
</div>