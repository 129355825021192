import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonServiceService } from 'src/app/Services/common-service.service';
declare var $: any;


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  open: boolean = false
  loginDetails:any
  constructor(private router: Router, private service: CommonServiceService) { }
  ngOnInit(): void {
    // Subscribe and check for `null` values
    this.service.loginDetails$.subscribe((details) => {
      this.loginDetails = details; // Update the local value
      // If loginDetails is null, attempt to fetch again
      if (this.loginDetails == null) {
        // Replace with the actual login ID
        this.service.fetchLoginDetails();
      }
    });
  }
  logOut() {
    localStorage.removeItem('_id');
    localStorage.clear();
    this.router.navigateByUrl('/')
  }
  openSiddde() {
    this.open = !this.open;
    this.open == true ? $('.sidebar').addClass('open_siddde') : $('.sidebar').removeClass('open_siddde')
  }

}
