import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { catchError, retry, shareReplay, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { JsonPipe } from '@angular/common';
import { ToastEvokeService } from '@costlydeveloper/ngx-awesome-popup';
@Injectable({
  providedIn: 'root'
})
export class CommonServiceService {
  STORAGE_ID: any = environment.STORAGE_ID
  BROADCAST_ID: any = environment.BROADCAST_ID
  apiServerUrl = environment.serverBaseUrl

  // serverUrl = "http://192.168.1.164:3031/";  //local url
  // serverUrl = 'https://api.go4whatsup.com:8001/'   // Live url
  // serverUrl = 'https://api.go4whatsup.com:8002/'   // Staging url

  //url = "http://192.168.1.164:3031/";  //local url
  // url = 'https://api.go4whatsup.com:8001/'     // Live url
  url = 'https://api.go4whatsup.com:8002/'   // Staging url
  private loginDetailsSubject = new BehaviorSubject<any>(null); // Default value as null
  loginDetails$ = this.loginDetailsSubject.asObservable(); // Observable for components
  loginId:any
  constructor(private http: HttpClient, private toster: ToastEvokeService) {
  }

  setLoginDetailsValue(details: any): void {
    this.loginDetailsSubject.next(details);
  }

  getLoginDetailsValue(): any {
    return this.loginDetailsSubject.getValue(); // Get the current value
  }
  fetchLoginDetails(): void {
    setTimeout(() => {
      var loginId = localStorage.getItem('_id'); // Retrieve login ID from local storage
      console.log('loginId local',loginId);

      if (loginId) {
        this.http.get(this.apiServerUrl + `getUserDetailById/` + loginId)
          .pipe(
            retry(3) // Retry the API call up to 3 times on failure
          )
          .subscribe(
            (res: any) => {
              if (res?.ErrorCode === 200) {
                this.setLoginDetailsValue(res); // Update the shared data
              } else {
                this.showError();
              }
            },
            () => this.showError() // Handle final failure
          );
      }
      // else {
      //   this.showError(); // Handle case when loginId is not found in local storage
      // }
    }, 1000);
  }


  showError(): void {
    this.toster.warning('', 'Something went wrong').subscribe();
  }

   loginByAdminApi(data: any) {
    return this.postMethod(this.apiServerUrl + 'loginByAdmin', data)
  }
  gettoken() {
    return !!localStorage.getItem("_id");
  }

  login(data: any) {
    return this.postMethod(this.apiServerUrl + 'adminLogin', data)
  }

  getUserInfo(data: any) {
    return this.http.post<any>(this.apiServerUrl + 'UserInfoByid', data);
  }

  DashboardUserCount() {
    return this.getMethod(this.apiServerUrl + "DashboardUserCount")
  }

  getAllFilterUsers = (data: any) => {
    return this.postMethod(`${this.apiServerUrl + 'User/GetAllFilterUsers'}`, data)
  }

  getPaymentFailuar = (data: any) => {
    return this.postMethod(`${this.apiServerUrl + 'getPaymentFailuar'}`, data)
  }

  getExpireTokenDetails(data: any) {
    return this.postMethod(`${this.apiServerUrl + 'getExpireTokenDetails'}`, data)
  }

  approveUser = (data: any, id: any) => {
    return this.postMethod(`${this.apiServerUrl}approveOrg/${id}`, data)
  }

  rejectUser(data: any, id: any) {
    // return this.postMethod(this.apiServerUrl + "updateUser", data);
    return this.postMethod(`${this.apiServerUrl}rejectOrg/${id}`, data)
  }

  getRejectLogData(id: any) {
    // return this.postMethod(this.apiServerUrl + "getAccoundRejectLog", data)
    return this.getMethod(`${this.apiServerUrl}getAccoundRejectLog/${id}`)
  }

  //Old
  getAllFeature(data: any) {
    return this.postMethod(`${this.apiServerUrl}package/GetAllAppFeature`, data)
    // return this.postMethod(`http://192.168.1.164:3031/package/GetAllAppFeature`,data)
  }
  getAdditionalFeature(data: any) {
    return this.postMethod(`${this.apiServerUrl}feature/GetAdditionalFeatures`, data)
  }

  //Get All Send Feature List
  getSuperAdminAdditionalAccountDetail(data: any) {
    return this.postMethod(`${this.apiServerUrl}getSuperAdminAdditionalAccountDetail`, data)
  }

  createAdditionalFeature(data: any) {
    return this.postMethod(`${this.apiServerUrl}additionalFeature/createAdditionalFeature`, data)
  }

  getExpierdUserPlanDetailsApi(data: any) {
    return this.postMethod(`${this.apiServerUrl}getExpierdUserPlanDetails`, data)
  }


  getMainBroadcastSchdulingList(data: any) {
    return this.postMethod(`${this.apiServerUrl}getMainBroadcastSchdulingListForAdmin`, data)
  }

  getMainBroadcastSchdulingDetails(data: any) {
    return this.postMethod(`${this.apiServerUrl}getMainBroadcastSchdulingDetailsForAdmin`, data)
  }


  getAllModuleWithMenu(data: any = '') {
    return this.http.get<any>(this.apiServerUrl + 'getAllModuleWithMenu' + data);
  }

  createFeature = (data: any) => {
    return this.postMethod(`${this.apiServerUrl}package/CreateFeature`, data);
  }

  updateFeature = (id: any, data: any) => {
    return this.postMethod(`${this.apiServerUrl + 'package/UpdateAppFeature/' + id}`, data);
  }

  getPackageAllData(data: any) {
    return this.postMethod(`${this.apiServerUrl}package/getAllPackage`, data)
  }

  getAllPakageForAdmin(data: any) { //New ALl pAckage List
    return this.postMethod(`${this.apiServerUrl}getAllPakageForAdmin`, data)
  }

  assignPackageBySuperadminApi(data: any) {
    return this.postMethod(`${this.apiServerUrl}assignPackageBySuperadmin`, data)
  }

  renewPackageBySuperAdmin(data: any) {
    return this.postMethod(`${this.apiServerUrl}multipleplan/renewPackageBySuperAdmin`, data)
  }

  createPackage(data: any) {
    return this.postMethod(this.apiServerUrl + 'package/CreatePackage', data)
  }

  updatePackage = (data: any, id: any) => {
    return this.postMethod(`${this.apiServerUrl}updatePackageById/${id}`, data);
  }

  updatePackageStatus(data: any) {
    return this.postMethod(`${this.apiServerUrl}updatePackageFlag`, data)
  }

  getPackageActiveById = (id: any) => {
    return this.getMethod(`${this.apiServerUrl + 'package/PackageActiveDeactiveById/' + id}`);
  }

  getAllPaymentGetway() {
    return this.getMethod(`${this.apiServerUrl}getAllPaymentGetway`)
  }

  updatePaymentGetwayById(id: any, data: any) {
    return this.postMethod(`${this.apiServerUrl}updatePaymentGetwayById/${id}`, data)
  }

  getpackageWiseData() {
    return this.getMethod(`${this.apiServerUrl + 'DashboardPackageCount'}`);
  }

  getDataByPackageExpire() {
    return this.getMethod(`${this.apiServerUrl + 'getUserCountOfPackageexpier'}`);
  }

  getDataByPackageExpireNew() {
    return this.getMethod(`${this.apiServerUrl + 'getUserCountOfPackageexpierNew'}`);
  }


  getPackageDetailsByPackageID(id: any) {
    return this.getMethod(`${this.apiServerUrl}getAssignPackageByuserId/${id}`);
  }

  getUserAccountDetail(data: any) {
    return this.postMethod(`${this.apiServerUrl}getUserAccountDetail`, data);
  }

  getUserPaymentHistory(data: any) {
    return this.postMethod(`${this.apiServerUrl}paymentHistoryByuser`, data);

  }

  getPackageDetailsInExcelApi(data: any) {
    return this.postMethod(`${this.apiServerUrl}getPackageDetailsInExcel`, data);

  }

  GetAllOrgByStatus(data: any) {
    return this.postMethod(`${this.apiServerUrl}GetAllOrgByStatus`, data);
  }

  getAllGroup(data: any): Observable<any> {
    return this.postMethod(`${this.apiServerUrl}getAllGroup`, data).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }


  getAllCustomer(data: any): Observable<any> {
    return this.postMethod(`${this.apiServerUrl}getGroupCustomerByOrgId`, data).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  getContent(data: any) {
    return this.postMethod(`${this.apiServerUrl}getHelpSupport`, data);
  }

  updateContent(data: any, id: any) {
    return this.postMethod(`${this.apiServerUrl}updateHelpSupport/` + id, data);
  }

  getUserByGroupId(data: any): Observable<any> {
    return this.postMethod(`${this.apiServerUrl}getGroupCustomerByGroupId`, data).pipe(
      catchError(error => {
        return throwError(error);
      })
    );;
  }

  createFAQ(data: any) {
    return this.postMethod(`${this.apiServerUrl}createFandQ`, data);
  }
  // updateFAQ(data: any) {
  //   return this.postMethod(`${this.apiServerUrl}createFandQ`, data);
  // }
  updateFandQByid(data: any, id: any) {
    return this.postMethod(`${this.apiServerUrl}updateFandQByid/${id}`, data);
  }
  getAllFandQ() {
    return this.getMethod(`${this.apiServerUrl}getAllFandQ`);
  }

  deleteFandQByid(id: any) {
    return this.postMethod(`${this.apiServerUrl}deleteFandQByid/${id}`, {});
  }


  dashboardSubscribeCount() {
    return this.getMethod(`${this.apiServerUrl}DashboardSubscriptionUserCount`)
  }

  getAllIntegrationByorgId(data: any) {
    return this.postMethod(`${this.apiServerUrl}getAllIntegrationByorgId`, data);

  }
  activeDactiveIntegration(data: any) {
    return this.postMethod(`${this.apiServerUrl}activeDactiveIntegration`, data);

  }

  deleteBusinessUser(id: any) {
    return this.deleteMethod(`${this.apiServerUrl}deleteOrgById/${id}`);
  }

  getBroadcastInprogressDetails(data: any) {
    return this.postMethod(`${this.apiServerUrl}getAllrunningbroadcast`, data)
  }


  GetAllContact(data: any) {
    return this.postMethod(`${this.apiServerUrl}getAllCustomerList`, data);
  }


  getAdminQueryList(data: any) {
    return this.postMethod(`${this.apiServerUrl}adminqueries/getActivefilteredwithPagination`, data);
  }


  getAdminReplyListById(data: any) {
    return this.postMethod(`${this.apiServerUrl}adminqueriesans/getAdminQueryAnsByAdminQueryId`, data);
  }


  submitAdminAnswer(data: any) {
    return this.postMethod(`${this.apiServerUrl}create/adminqueriesans`, data);
  }



  getPackageHistoryList(data: any) {
    return this.postMethod(`${this.apiServerUrl}package/getPackageHistoryByuserId`, data);
  }

  getIntegrationList(data: any) {
    return this.postMethod(`${this.apiServerUrl}integration`, data);
  }

  deActiveFeatureById(id: any, status: any) {
    if (status == 1) { //activate feature
      return this.putMethod(`${this.apiServerUrl}activeFeatureById/${id}`, {})
    } else { //de-activate feature
      return this.deleteMethod(`${this.apiServerUrl}deActiveFeatureById/${id}`);
    }
  }

  getAddOnHistoryList(data: any) {
    return this.postMethod(`${this.apiServerUrl}assignAddons/getUserAddonsByOrgId`, data);
  }

  getAddOnHistoryListApi(data: any) {
    return this.postMethod(`${this.apiServerUrl}assignAddons/getasssignAddonsDetails`, data);
  }

  getGroupList(data: any) {
    return this.postMethod(`${this.apiServerUrl}getAllGroupListByOrgId`, data);
  }

  getContactList(data: any) {
    return this.postMethod(`${this.apiServerUrl}getContactListByOrgIdFilter`, data);
  }

  getBroadcastList(data: any) {
    return this.postMethod(`${this.apiServerUrl}getAllBroadcastListWithGrpTempl`, data);
  }
  getBroadcastListApi(data: any) {
    return this.postMethod(`${this.apiServerUrl}getSuperAdminBroadcastListWithGrpTemplate`, data);
  }

  getChatbotList(data: any) {
    return this.postMethod(`${this.apiServerUrl}getAllChatbotListByorgId`, data);
  }


  getQueriesList(data: any) {
    return this.postMethod(`${this.apiServerUrl}adminqueries/getActivefilteredListOrgWithPagination`, data);
  }

  getCollaboratorList(data: any) {
    return this.postMethod(`${this.apiServerUrl}getAllCollaboratorsByOrgId`, data);
  }

  getPackageHistoryDetails(data: any) {
    return this.postMethod(`${this.apiServerUrl}getPackgeQuotaById`, data);
  }

  getUserUsesDatakota(data: any) {
    return this.postMethod(`${this.apiServerUrl}getUserAccountDetail`, data);
  }
// Old api
  getSocialCampaign(data: any) {
    return this.postMethod(`${this.apiServerUrl}webhook/getSocialMediaTicketAdmin`, data);
  }
// New api
  getSocialCampaignApi(data: any) {
    return this.postMethod(`${this.apiServerUrl}webhook/getSocialMediaTicketSuperAdmin`, data);
  }

  getChatbotById(id: any) {
    return this.getMethod(this.apiServerUrl + 'getAdminChatbotById/' + id);
  }

  receivedBroadcastUserDetail(data: any) {
    return this.postMethod(`${this.apiServerUrl}receivedBroadcastUserDetailSuperadmin`, data);
  }

  getBroadcastById(data: any) {
    return this.postMethod(`${this.apiServerUrl}getBroadcastByIdSuperadmin`, data);
  }

  getOrgWiseBroadcastDetailsApi(data: any) {
    return this.postMethod(`${this.apiServerUrl}getOrgWiseBroadcastDetails`, data);
  }

  getOrgDetailByOrgIdApi(data: any) {
    return this.postMethod(`${this.apiServerUrl}getOrgDetailByOrgId`, data);
  }

  customerDetailsGet(data: any) {
    return this.postMethod(`${this.apiServerUrl}getGroupCustomerByGroupId?status=All`, data);
  }


  updateMenuStatus(data: any) {
    return this.postMethod(`${this.apiServerUrl}updateMenuStatusBySuperAdmin`, data);
  }

  getExapandableData() {
    return this.getMethod(this.apiServerUrl + 'getSuperSetting');
  }

  updateExapandableData(data: any) {
    return this.postMethod(`${this.apiServerUrl}updateSuperSettingStatus`, data);
  }



  getAllModule() {
    return this.getMethod(this.apiServerUrl + 'getAllModules');
  }



  createNewModule(data: any) {
    return this.postMethod(`${this.apiServerUrl}createModule`, data);
  }


  updateModule(data: any, id: any) {
    return this.postMethod(`${this.apiServerUrl}updateModuleById/${id}`, data);
  }


  getModuleDetailsByID(id: any) {
    return this.getMethod(this.apiServerUrl + `getModuleById/${id}`);
  }

  deleteModule(id: any) {
    return this.postMethod(this.apiServerUrl + `deleteModuleById/${id}`, {});
  }



  getAllMenu() {
    return this.getMethod(this.apiServerUrl + 'getAllMenu');
  }

  createMenu(data: any) {
    return this.postMethod(`${this.apiServerUrl}createMenu`, data);
  }

  getMenuDetailsByID(id: any) {
    return this.getMethod(this.apiServerUrl + `getMenuById/${id}`);
  }

  updateMenu(id: any, data: any) {
    return this.postMethod(`${this.apiServerUrl}updateMenuById/${id}`, data);
  }

  deleteMenu(id: any) {
    return this.postMethod(this.apiServerUrl + `deleteMenuById/${id}`, {});
  }


  getErrorLog(data: any) {
    return this.postMethod(this.apiServerUrl + `getAllErrorLog?limit=${data.limit}&skip=${data.skip}`, data);
  }

  getBroadcastSchedule(data: any) {
    return this.postMethod(this.apiServerUrl + `getAllOrgBroadcast`, data);
  }
// old api call
  getBroadcastDetailByOrgWise(data: any) {
    return this.postMethod(this.apiServerUrl + `getBroadcastDetailByOrgWise`, data);
  }
  // new api call
  getBroadcastDetailByOrgWiseApi(data: any) {
    return this.postMethod(this.apiServerUrl + `getApproveAccuntBroadcastDetailByOrgWise`, data);
  }

  packageWiseDataNew(data: any) {
    return this.getMethod(`${this.apiServerUrl}DashboardPackageCountnew?startDate=${data.startDate}&endDate=${data.endDate}`);
  }

  getSubscriptionCounts(data: any) {

    return this.getMethod(`${this.apiServerUrl}userSubscriptionCounts?startDate=${data.startDate}&endDate=${data.endDate}`);
  }

  getDataForPipChart(data: any) {

    return this.getMethod(`${this.apiServerUrl}getDataForPipChart`);
  }


  // API for Email content

  getdymanicNotifyTexts() {
    return this.getMethod(this.apiServerUrl + `getall/dymanicNotifyTexts`);
  }

  createEmailContainer(data: any) {
    return this.postMethod(`${this.apiServerUrl}create/dymanicNotifyTexts`, data);
  }
  getDymanicNotifyTextsById(Id: any) {
    return this.getMethod(this.apiServerUrl + `get/dymanicNotifyTextsbyid/${Id}`);
  }

  UpdateEmailContainer(data: any) {
    return this.putMethod(this.apiServerUrl + `dymanicNotifyTexts/update/${data.id}`, data);
  }


  getUserPackageDetails(data: any) {
    return this.postMethod(this.apiServerUrl + `getAllUserPkageDetail`, data);
  }

  cancelSubsciptionByAdminApi(data: any) {
    return this.postMethod(this.apiServerUrl + `cancelSubsciptionByAdmin`, data);
  }
  servertime() {
    return this.http.get<any>(this.apiServerUrl + 'servertime');
  }

  getMaintenanceStatus() {
    return this.getMethod(this.apiServerUrl + `getMaintenanceStatus`);
  }

  getMaintenanceDetails(id: any) {
    return this.getMethod(this.apiServerUrl + `getMaintenanceModelById/` + id);

  }
  updateMaintenanceDetails(id: any, data: any) {
    return this.postMethod(this.apiServerUrl + `updateMaintenanceByid/` + id, data);
  }

  UpdateDefaultOTPByOrgId(data: any) {
    return this.postMethod(this.apiServerUrl + `org/onOffORGDefaultOTPByOrgId/`, data);
  }
  UpdateAllDefaultOTP(data: any) {
    return this.postMethod(this.apiServerUrl + `org/onOffAllORGDefaultOTP/`, data);
  }


  //----------Charts API----------

  //Get Gateway Chart
  getGatewayChart() {
    return this.getMethod(`${this.apiServerUrl}getDataPkgForPipChart`)
  }

  getBusinessTypeWisForPipChart() {
    return this.getMethod(`${this.apiServerUrl}getBusinessTypeWisForPipChart`)
  }

  getCountryWisForPipChart() {
    return this.getMethod(`${this.apiServerUrl}getCountryWisForPipChart`)
  }

  getAssignPackageDetailExcel(data:any) {
    return this.postMethod(this.apiServerUrl + `assingpackage/getAssignPackageDetailExcel`, data);
  }

  getCountOfPackageUsers(data:any) {
    return this.postMethod(this.apiServerUrl + `getCountOfPackageUsers`, data);
  }
  //----------Charts API----------


  createUploadVersion(data: any) {
    return this.postMethod(this.apiServerUrl + 'uploadVersion/createuploadVersion', data)
  }

  uploadVersionList(data: any) {
    return this.postMethod(this.apiServerUrl + 'uploadVersion/getAlluploadVersionModel', data)
  }

  uploadVersionDetail(data: any) {
    return this.postMethod(this.apiServerUrl + 'uploadVersion/getuploadVersionById', data)
  }

  updateUploadVersion(data: any) {
    return this.postMethod(this.apiServerUrl + 'uploadVersion/updateuploadVersionById', data)
  }

  getUserPkgDetail(data: any) {
    return this.postMethod(this.apiServerUrl + 'getUserPkgDetail', data)
  }

  private countryData$: Observable<any> | null = null; // Observable to store the cached API response
  getCountryApiCallOneTime(): Observable<any> {
    // If the country data observable is already available, return it
    if (this.countryData$) {
      return this.countryData$;
    }
    // Make the API call and store the result in the observable
    this.countryData$ = this.http.get('https://api.country.is/').pipe(
      tap(data => console.log('API response:', data)), // Log API response for debugging
      shareReplay(1), // Share the latest result and cache it
      retry(3),  // Retry the request up to 3 times
      catchError(this.handleError) // Handle error if request fails after 3 retries
    );

    return this.countryData$;
  }

  // Error handling logic
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // Client-side or network error
      console.error('An error occurred:', error.error.message);
    } else {
      // Backend returned an unsuccessful response code
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError('Something went wrong; please try again later.');
  }

  // -----------------------------------------------------------------

  postMethod(url: any, data: any) {
    return this.http.post<any>(url, data)
  }

  getMethod(url: any) {
    return this.http.get<any>(url)
  }

  putMethod(url: any, data: any) {
    return this.http.put<any>(url, data)
  }

  deleteMethod(url: any) {
    return this.http.delete<any>(url)
  }

  // getLoginDetails(id:any) {
  //   return this.getMethod(this.apiServerUrl + `getUserDetailById/` + id,);
  // }


  // getHeaderToken(){
  //   var val : any = localStorage.getItem('businessUserInfo')
  //   let tokenVal = JSON.parse(val)
  // }

  countryJsonWithCurrencyCode = [
    {
      countryCode: 'AD',
      countryName: 'Andorra',
      currencyCode: 'EUR',
      population: '84000',
      capital: 'Andorra la Vella',
      continentName: 'Europe',
    },
    {
      countryCode: 'AE',
      countryName: 'United Arab Emirates',
      currencyCode: 'AED',
      population: '4975593',
      capital: 'Abu Dhabi',
      continentName: 'Asia',
    },
    {
      countryCode: 'AF',
      countryName: 'Afghanistan',
      currencyCode: 'AFN',
      population: '29121286',
      capital: 'Kabul',
      continentName: 'Asia',
    },
    {
      countryCode: 'AG',
      countryName: 'Antigua and Barbuda',
      currencyCode: 'XCD',
      population: '86754',
      capital: "St. John's",
      continentName: 'North America',
    },
    {
      countryCode: 'AI',
      countryName: 'Anguilla',
      currencyCode: 'XCD',
      population: '13254',
      capital: 'The Valley',
      continentName: 'North America',
    },
    {
      countryCode: 'AL',
      countryName: 'Albania',
      currencyCode: 'ALL',
      population: '2986952',
      capital: 'Tirana',
      continentName: 'Europe',
    },
    {
      countryCode: 'AM',
      countryName: 'Armenia',
      currencyCode: 'AMD',
      population: '2968000',
      capital: 'Yerevan',
      continentName: 'Asia',
    },
    {
      countryCode: 'AO',
      countryName: 'Angola',
      currencyCode: 'AOA',
      population: '13068161',
      capital: 'Luanda',
      continentName: 'Africa',
    },
    {
      countryCode: 'AQ',
      countryName: 'Antarctica',
      currencyCode: '',
      population: '0',
      capital: '',
      continentName: 'Antarctica',
    },
    {
      countryCode: 'AR',
      countryName: 'Argentina',
      currencyCode: 'ARS',
      population: '41343201',
      capital: 'Buenos Aires',
      continentName: 'South America',
    },
    {
      countryCode: 'AS',
      countryName: 'American Samoa',
      currencyCode: 'USD',
      population: '57881',
      capital: 'Pago Pago',
      continentName: 'Oceania',
    },
    {
      countryCode: 'AT',
      countryName: 'Austria',
      currencyCode: 'EUR',
      population: '8205000',
      capital: 'Vienna',
      continentName: 'Europe',
    },
    {
      countryCode: 'AU',
      countryName: 'Australia',
      currencyCode: 'AUD',
      population: '21515754',
      capital: 'Canberra',
      continentName: 'Oceania',
    },
    {
      countryCode: 'AW',
      countryName: 'Aruba',
      currencyCode: 'AWG',
      population: '71566',
      capital: 'Oranjestad',
      continentName: 'North America',
    },
    {
      countryCode: 'AX',
      countryName: 'Åland',
      currencyCode: 'EUR',
      population: '26711',
      capital: 'Mariehamn',
      continentName: 'Europe',
    },
    {
      countryCode: 'AZ',
      countryName: 'Azerbaijan',
      currencyCode: 'AZN',
      population: '8303512',
      capital: 'Baku',
      continentName: 'Asia',
    },
    {
      countryCode: 'BA',
      countryName: 'Bosnia and Herzegovina',
      currencyCode: 'BAM',
      population: '4590000',
      capital: 'Sarajevo',
      continentName: 'Europe',
    },
    {
      countryCode: 'BB',
      countryName: 'Barbados',
      currencyCode: 'BBD',
      population: '285653',
      capital: 'Bridgetown',
      continentName: 'North America',
    },
    {
      countryCode: 'BD',
      countryName: 'Bangladesh',
      currencyCode: 'BDT',
      population: '156118464',
      capital: 'Dhaka',
      continentName: 'Asia',
    },
    {
      countryCode: 'BE',
      countryName: 'Belgium',
      currencyCode: 'EUR',
      population: '10403000',
      capital: 'Brussels',
      continentName: 'Europe',
    },
    {
      countryCode: 'BF',
      countryName: 'Burkina Faso',
      currencyCode: 'XOF',
      population: '16241811',
      capital: 'Ouagadougou',
      continentName: 'Africa',
    },
    {
      countryCode: 'BG',
      countryName: 'Bulgaria',
      currencyCode: 'BGN',
      population: '7148785',
      capital: 'Sofia',
      continentName: 'Europe',
    },
    {
      countryCode: 'BH',
      countryName: 'Bahrain',
      currencyCode: 'BHD',
      population: '738004',
      capital: 'Manama',
      continentName: 'Asia',
    },
    {
      countryCode: 'BI',
      countryName: 'Burundi',
      currencyCode: 'BIF',
      population: '9863117',
      capital: 'Bujumbura',
      continentName: 'Africa',
    },
    {
      countryCode: 'BJ',
      countryName: 'Benin',
      currencyCode: 'XOF',
      population: '9056010',
      capital: 'Porto-Novo',
      continentName: 'Africa',
    },
    {
      countryCode: 'BL',
      countryName: 'Saint Barthélemy',
      currencyCode: 'EUR',
      population: '8450',
      capital: 'Gustavia',
      continentName: 'North America',
    },
    {
      countryCode: 'BM',
      countryName: 'Bermuda',
      currencyCode: 'BMD',
      population: '65365',
      capital: 'Hamilton',
      continentName: 'North America',
    },
    {
      countryCode: 'BN',
      countryName: 'Brunei',
      currencyCode: 'BND',
      population: '395027',
      capital: 'Bandar Seri Begawan',
      continentName: 'Asia',
    },
    {
      countryCode: 'BO',
      countryName: 'Bolivia',
      currencyCode: 'BOB',
      population: '9947418',
      capital: 'Sucre',
      continentName: 'South America',
    },
    {
      countryCode: 'BQ',
      countryName: 'Bonaire',
      currencyCode: 'USD',
      population: '18012',
      capital: 'Kralendijk',
      continentName: 'North America',
    },
    {
      countryCode: 'BR',
      countryName: 'Brazil',
      currencyCode: 'BRL',
      population: '201103330',
      capital: 'Brasília',
      continentName: 'South America',
    },
    {
      countryCode: 'BS',
      countryName: 'Bahamas',
      currencyCode: 'BSD',
      population: '301790',
      capital: 'Nassau',
      continentName: 'North America',
    },
    {
      countryCode: 'BT',
      countryName: 'Bhutan',
      currencyCode: 'BTN',
      population: '699847',
      capital: 'Thimphu',
      continentName: 'Asia',
    },
    {
      countryCode: 'BV',
      countryName: 'Bouvet Island',
      currencyCode: 'NOK',
      population: '0',
      capital: '',
      continentName: 'Antarctica',
    },
    {
      countryCode: 'BW',
      countryName: 'Botswana',
      currencyCode: 'BWP',
      population: '2029307',
      capital: 'Gaborone',
      continentName: 'Africa',
    },
    {
      countryCode: 'BY',
      countryName: 'Belarus',
      currencyCode: 'BYR',
      population: '9685000',
      capital: 'Minsk',
      continentName: 'Europe',
    },
    {
      countryCode: 'BZ',
      countryName: 'Belize',
      currencyCode: 'BZD',
      population: '314522',
      capital: 'Belmopan',
      continentName: 'North America',
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      currencyCode: 'CAD',
      population: '33679000',
      capital: 'Ottawa',
      continentName: 'North America',
    },
    {
      countryCode: 'CC',
      countryName: 'Cocos [Keeling] Islands',
      currencyCode: 'AUD',
      population: '628',
      capital: 'West Island',
      continentName: 'Asia',
    },
    {
      countryCode: 'CD',
      countryName: 'Democratic Republic of the Congo',
      currencyCode: 'CDF',
      population: '70916439',
      capital: 'Kinshasa',
      continentName: 'Africa',
    },
    {
      countryCode: 'CF',
      countryName: 'Central African Republic',
      currencyCode: 'XAF',
      population: '4844927',
      capital: 'Bangui',
      continentName: 'Africa',
    },
    {
      countryCode: 'CG',
      countryName: 'Republic of the Congo',
      currencyCode: 'XAF',
      population: '3039126',
      capital: 'Brazzaville',
      continentName: 'Africa',
    },
    {
      countryCode: 'CH',
      countryName: 'Switzerland',
      currencyCode: 'CHF',
      population: '7581000',
      capital: 'Bern',
      continentName: 'Europe',
    },
    {
      countryCode: 'CI',
      countryName: 'Ivory Coast',
      currencyCode: 'XOF',
      population: '21058798',
      capital: 'Yamoussoukro',
      continentName: 'Africa',
    },
    {
      countryCode: 'CK',
      countryName: 'Cook Islands',
      currencyCode: 'NZD',
      population: '21388',
      capital: 'Avarua',
      continentName: 'Oceania',
    },
    {
      countryCode: 'CL',
      countryName: 'Chile',
      currencyCode: 'CLP',
      population: '16746491',
      capital: 'Santiago',
      continentName: 'South America',
    },
    {
      countryCode: 'CM',
      countryName: 'Cameroon',
      currencyCode: 'XAF',
      population: '19294149',
      capital: 'Yaoundé',
      continentName: 'Africa',
    },
    {
      countryCode: 'CN',
      countryName: 'China',
      currencyCode: 'CNY',
      population: '1330044000',
      capital: 'Beijing',
      continentName: 'Asia',
    },
    {
      countryCode: 'CO',
      countryName: 'Colombia',
      currencyCode: 'COP',
      population: '47790000',
      capital: 'Bogotá',
      continentName: 'South America',
    },
    {
      countryCode: 'CR',
      countryName: 'Costa Rica',
      currencyCode: 'CRC',
      population: '4516220',
      capital: 'San José',
      continentName: 'North America',
    },
    {
      countryCode: 'CU',
      countryName: 'Cuba',
      currencyCode: 'CUP',
      population: '11423000',
      capital: 'Havana',
      continentName: 'North America',
    },
    {
      countryCode: 'CV',
      countryName: 'Cape Verde',
      currencyCode: 'CVE',
      population: '508659',
      capital: 'Praia',
      continentName: 'Africa',
    },
    {
      countryCode: 'CW',
      countryName: 'Curacao',
      currencyCode: 'ANG',
      population: '141766',
      capital: 'Willemstad',
      continentName: 'North America',
    },
    {
      countryCode: 'CX',
      countryName: 'Christmas Island',
      currencyCode: 'AUD',
      population: '1500',
      capital: 'Flying Fish Cove',
      continentName: 'Asia',
    },
    {
      countryCode: 'CY',
      countryName: 'Cyprus',
      currencyCode: 'EUR',
      population: '1102677',
      capital: 'Nicosia',
      continentName: 'Europe',
    },
    {
      countryCode: 'CZ',
      countryName: 'Czechia',
      currencyCode: 'CZK',
      population: '10476000',
      capital: 'Prague',
      continentName: 'Europe',
    },
    {
      countryCode: 'DE',
      countryName: 'Germany',
      currencyCode: 'EUR',
      population: '81802257',
      capital: 'Berlin',
      continentName: 'Europe',
    },
    {
      countryCode: 'DJ',
      countryName: 'Djibouti',
      currencyCode: 'DJF',
      population: '740528',
      capital: 'Djibouti',
      continentName: 'Africa',
    },
    {
      countryCode: 'DK',
      countryName: 'Denmark',
      currencyCode: 'DKK',
      population: '5484000',
      capital: 'Copenhagen',
      continentName: 'Europe',
    },
    {
      countryCode: 'DM',
      countryName: 'Dominica',
      currencyCode: 'XCD',
      population: '72813',
      capital: 'Roseau',
      continentName: 'North America',
    },
    {
      countryCode: 'DO',
      countryName: 'Dominican Republic',
      currencyCode: 'DOP',
      population: '9823821',
      capital: 'Santo Domingo',
      continentName: 'North America',
    },
    {
      countryCode: 'DZ',
      countryName: 'Algeria',
      currencyCode: 'DZD',
      population: '34586184',
      capital: 'Algiers',
      continentName: 'Africa',
    },
    {
      countryCode: 'EC',
      countryName: 'Ecuador',
      currencyCode: 'USD',
      population: '14790608',
      capital: 'Quito',
      continentName: 'South America',
    },
    {
      countryCode: 'EE',
      countryName: 'Estonia',
      currencyCode: 'EUR',
      population: '1291170',
      capital: 'Tallinn',
      continentName: 'Europe',
    },
    {
      countryCode: 'EG',
      countryName: 'Egypt',
      currencyCode: 'EGP',
      population: '80471869',
      capital: 'Cairo',
      continentName: 'Africa',
    },
    {
      countryCode: 'EH',
      countryName: 'Western Sahara',
      currencyCode: 'MAD',
      population: '273008',
      capital: 'Laâyoune / El Aaiún',
      continentName: 'Africa',
    },
    {
      countryCode: 'ER',
      countryName: 'Eritrea',
      currencyCode: 'ERN',
      population: '5792984',
      capital: 'Asmara',
      continentName: 'Africa',
    },
    {
      countryCode: 'ES',
      countryName: 'Spain',
      currencyCode: 'EUR',
      population: '46505963',
      capital: 'Madrid',
      continentName: 'Europe',
    },
    {
      countryCode: 'ET',
      countryName: 'Ethiopia',
      currencyCode: 'ETB',
      population: '88013491',
      capital: 'Addis Ababa',
      continentName: 'Africa',
    },
    {
      countryCode: 'FI',
      countryName: 'Finland',
      currencyCode: 'EUR',
      population: '5244000',
      capital: 'Helsinki',
      continentName: 'Europe',
    },
    {
      countryCode: 'FJ',
      countryName: 'Fiji',
      currencyCode: 'FJD',
      population: '875983',
      capital: 'Suva',
      continentName: 'Oceania',
    },
    {
      countryCode: 'FK',
      countryName: 'Falkland Islands',
      currencyCode: 'FKP',
      population: '2638',
      capital: 'Stanley',
      continentName: 'South America',
    },
    {
      countryCode: 'FM',
      countryName: 'Micronesia',
      currencyCode: 'USD',
      population: '107708',
      capital: 'Palikir',
      continentName: 'Oceania',
    },
    {
      countryCode: 'FO',
      countryName: 'Faroe Islands',
      currencyCode: 'DKK',
      population: '48228',
      capital: 'Tórshavn',
      continentName: 'Europe',
    },
    {
      countryCode: 'FR',
      countryName: 'France',
      currencyCode: 'EUR',
      population: '64768389',
      capital: 'Paris',
      continentName: 'Europe',
    },
    {
      countryCode: 'GA',
      countryName: 'Gabon',
      currencyCode: 'XAF',
      population: '1545255',
      capital: 'Libreville',
      continentName: 'Africa',
    },
    {
      countryCode: 'GB',
      countryName: 'United Kingdom',
      currencyCode: 'GBP',
      population: '62348447',
      capital: 'London',
      continentName: 'Europe',
    },
    {
      countryCode: 'GD',
      countryName: 'Grenada',
      currencyCode: 'XCD',
      population: '107818',
      capital: "St. George's",
      continentName: 'North America',
    },
    {
      countryCode: 'GE',
      countryName: 'Georgia',
      currencyCode: 'GEL',
      population: '4630000',
      capital: 'Tbilisi',
      continentName: 'Asia',
    },
    {
      countryCode: 'GF',
      countryName: 'French Guiana',
      currencyCode: 'EUR',
      population: '195506',
      capital: 'Cayenne',
      continentName: 'South America',
    },
    {
      countryCode: 'GG',
      countryName: 'Guernsey',
      currencyCode: 'GBP',
      population: '65228',
      capital: 'St Peter Port',
      continentName: 'Europe',
    },
    {
      countryCode: 'GH',
      countryName: 'Ghana',
      currencyCode: 'GHS',
      population: '24339838',
      capital: 'Accra',
      continentName: 'Africa',
    },
    {
      countryCode: 'GI',
      countryName: 'Gibraltar',
      currencyCode: 'GIP',
      population: '27884',
      capital: 'Gibraltar',
      continentName: 'Europe',
    },
    {
      countryCode: 'GL',
      countryName: 'Greenland',
      currencyCode: 'DKK',
      population: '56375',
      capital: 'Nuuk',
      continentName: 'North America',
    },
    {
      countryCode: 'GM',
      countryName: 'Gambia',
      currencyCode: 'GMD',
      population: '1593256',
      capital: 'Bathurst',
      continentName: 'Africa',
    },
    {
      countryCode: 'GN',
      countryName: 'Guinea',
      currencyCode: 'GNF',
      population: '10324025',
      capital: 'Conakry',
      continentName: 'Africa',
    },
    {
      countryCode: 'GP',
      countryName: 'Guadeloupe',
      currencyCode: 'EUR',
      population: '443000',
      capital: 'Basse-Terre',
      continentName: 'North America',
    },
    {
      countryCode: 'GQ',
      countryName: 'Equatorial Guinea',
      currencyCode: 'XAF',
      population: '1014999',
      capital: 'Malabo',
      continentName: 'Africa',
    },
    {
      countryCode: 'GR',
      countryName: 'Greece',
      currencyCode: 'EUR',
      population: '11000000',
      capital: 'Athens',
      continentName: 'Europe',
    },
    {
      countryCode: 'GS',
      countryName: 'South Georgia and the South Sandwich Islands',
      currencyCode: 'GBP',
      population: '30',
      capital: 'Grytviken',
      continentName: 'Antarctica',
    },
    {
      countryCode: 'GT',
      countryName: 'Guatemala',
      currencyCode: 'GTQ',
      population: '13550440',
      capital: 'Guatemala City',
      continentName: 'North America',
    },
    {
      countryCode: 'GU',
      countryName: 'Guam',
      currencyCode: 'USD',
      population: '159358',
      capital: 'Hagåtña',
      continentName: 'Oceania',
    },
    {
      countryCode: 'GW',
      countryName: 'Guinea-Bissau',
      currencyCode: 'XOF',
      population: '1565126',
      capital: 'Bissau',
      continentName: 'Africa',
    },
    {
      countryCode: 'GY',
      countryName: 'Guyana',
      currencyCode: 'GYD',
      population: '748486',
      capital: 'Georgetown',
      continentName: 'South America',
    },
    {
      countryCode: 'HK',
      countryName: 'Hong Kong',
      currencyCode: 'HKD',
      population: '6898686',
      capital: 'Hong Kong',
      continentName: 'Asia',
    },
    {
      countryCode: 'HM',
      countryName: 'Heard Island and McDonald Islands',
      currencyCode: 'AUD',
      population: '0',
      capital: '',
      continentName: 'Antarctica',
    },
    {
      countryCode: 'HN',
      countryName: 'Honduras',
      currencyCode: 'HNL',
      population: '7989415',
      capital: 'Tegucigalpa',
      continentName: 'North America',
    },
    {
      countryCode: 'HR',
      countryName: 'Croatia',
      currencyCode: 'HRK',
      population: '4284889',
      capital: 'Zagreb',
      continentName: 'Europe',
    },
    {
      countryCode: 'HT',
      countryName: 'Haiti',
      currencyCode: 'HTG',
      population: '9648924',
      capital: 'Port-au-Prince',
      continentName: 'North America',
    },
    {
      countryCode: 'HU',
      countryName: 'Hungary',
      currencyCode: 'HUF',
      population: '9982000',
      capital: 'Budapest',
      continentName: 'Europe',
    },
    {
      countryCode: 'ID',
      countryName: 'Indonesia',
      currencyCode: 'IDR',
      population: '242968342',
      capital: 'Jakarta',
      continentName: 'Asia',
    },
    {
      countryCode: 'IE',
      countryName: 'Ireland',
      currencyCode: 'EUR',
      population: '4622917',
      capital: 'Dublin',
      continentName: 'Europe',
    },
    {
      countryCode: 'IL',
      countryName: 'Israel',
      currencyCode: 'ILS',
      population: '7353985',
      capital: '',
      continentName: 'Asia',
    },
    {
      countryCode: 'IM',
      countryName: 'Isle of Man',
      currencyCode: 'GBP',
      population: '75049',
      capital: 'Douglas',
      continentName: 'Europe',
    },
    {
      countryCode: 'IN',
      countryName: 'India',
      currencyCode: 'INR',
      population: '1173108018',
      capital: 'New Delhi',
      continentName: 'Asia',
    },
    {
      countryCode: 'IO',
      countryName: 'British Indian Ocean Territory',
      currencyCode: 'USD',
      population: '4000',
      capital: '',
      continentName: 'Asia',
    },
    {
      countryCode: 'IQ',
      countryName: 'Iraq',
      currencyCode: 'IQD',
      population: '29671605',
      capital: 'Baghdad',
      continentName: 'Asia',
    },
    {
      countryCode: 'IR',
      countryName: 'Iran',
      currencyCode: 'IRR',
      population: '76923300',
      capital: 'Tehran',
      continentName: 'Asia',
    },
    {
      countryCode: 'IS',
      countryName: 'Iceland',
      currencyCode: 'ISK',
      population: '308910',
      capital: 'Reykjavik',
      continentName: 'Europe',
    },
    {
      countryCode: 'IT',
      countryName: 'Italy',
      currencyCode: 'EUR',
      population: '60340328',
      capital: 'Rome',
      continentName: 'Europe',
    },
    {
      countryCode: 'JE',
      countryName: 'Jersey',
      currencyCode: 'GBP',
      population: '90812',
      capital: 'Saint Helier',
      continentName: 'Europe',
    },
    {
      countryCode: 'JM',
      countryName: 'Jamaica',
      currencyCode: 'JMD',
      population: '2847232',
      capital: 'Kingston',
      continentName: 'North America',
    },
    {
      countryCode: 'JO',
      countryName: 'Jordan',
      currencyCode: 'JOD',
      population: '6407085',
      capital: 'Amman',
      continentName: 'Asia',
    },
    {
      countryCode: 'JP',
      countryName: 'Japan',
      currencyCode: 'JPY',
      population: '127288000',
      capital: 'Tokyo',
      continentName: 'Asia',
    },
    {
      countryCode: 'KE',
      countryName: 'Kenya',
      currencyCode: 'KES',
      population: '40046566',
      capital: 'Nairobi',
      continentName: 'Africa',
    },
    {
      countryCode: 'KG',
      countryName: 'Kyrgyzstan',
      currencyCode: 'KGS',
      population: '5776500',
      capital: 'Bishkek',
      continentName: 'Asia',
    },
    {
      countryCode: 'KH',
      countryName: 'Cambodia',
      currencyCode: 'KHR',
      population: '14453680',
      capital: 'Phnom Penh',
      continentName: 'Asia',
    },
    {
      countryCode: 'KI',
      countryName: 'Kiribati',
      currencyCode: 'AUD',
      population: '92533',
      capital: 'Tarawa',
      continentName: 'Oceania',
    },
    {
      countryCode: 'KM',
      countryName: 'Comoros',
      currencyCode: 'KMF',
      population: '773407',
      capital: 'Moroni',
      continentName: 'Africa',
    },
    {
      countryCode: 'KN',
      countryName: 'Saint Kitts and Nevis',
      currencyCode: 'XCD',
      population: '51134',
      capital: 'Basseterre',
      continentName: 'North America',
    },
    {
      countryCode: 'KP',
      countryName: 'North Korea',
      currencyCode: 'KPW',
      population: '22912177',
      capital: 'Pyongyang',
      continentName: 'Asia',
    },
    {
      countryCode: 'KR',
      countryName: 'South Korea',
      currencyCode: 'KRW',
      population: '48422644',
      capital: 'Seoul',
      continentName: 'Asia',
    },
    {
      countryCode: 'KW',
      countryName: 'Kuwait',
      currencyCode: 'KWD',
      population: '2789132',
      capital: 'Kuwait City',
      continentName: 'Asia',
    },
    {
      countryCode: 'KY',
      countryName: 'Cayman Islands',
      currencyCode: 'KYD',
      population: '44270',
      capital: 'George Town',
      continentName: 'North America',
    },
    {
      countryCode: 'KZ',
      countryName: 'Kazakhstan',
      currencyCode: 'KZT',
      population: '15340000',
      capital: 'Astana',
      continentName: 'Asia',
    },
    {
      countryCode: 'LA',
      countryName: 'Laos',
      currencyCode: 'LAK',
      population: '6368162',
      capital: 'Vientiane',
      continentName: 'Asia',
    },
    {
      countryCode: 'LB',
      countryName: 'Lebanon',
      currencyCode: 'LBP',
      population: '4125247',
      capital: 'Beirut',
      continentName: 'Asia',
    },
    {
      countryCode: 'LC',
      countryName: 'Saint Lucia',
      currencyCode: 'XCD',
      population: '160922',
      capital: 'Castries',
      continentName: 'North America',
    },
    {
      countryCode: 'LI',
      countryName: 'Liechtenstein',
      currencyCode: 'CHF',
      population: '35000',
      capital: 'Vaduz',
      continentName: 'Europe',
    },
    {
      countryCode: 'LK',
      countryName: 'Sri Lanka',
      currencyCode: 'LKR',
      population: '21513990',
      capital: 'Colombo',
      continentName: 'Asia',
    },
    {
      countryCode: 'LR',
      countryName: 'Liberia',
      currencyCode: 'LRD',
      population: '3685076',
      capital: 'Monrovia',
      continentName: 'Africa',
    },
    {
      countryCode: 'LS',
      countryName: 'Lesotho',
      currencyCode: 'LSL',
      population: '1919552',
      capital: 'Maseru',
      continentName: 'Africa',
    },
    {
      countryCode: 'LT',
      countryName: 'Lithuania',
      currencyCode: 'EUR',
      population: '2944459',
      capital: 'Vilnius',
      continentName: 'Europe',
    },
    {
      countryCode: 'LU',
      countryName: 'Luxembourg',
      currencyCode: 'EUR',
      population: '497538',
      capital: 'Luxembourg',
      continentName: 'Europe',
    },
    {
      countryCode: 'LV',
      countryName: 'Latvia',
      currencyCode: 'EUR',
      population: '2217969',
      capital: 'Riga',
      continentName: 'Europe',
    },
    {
      countryCode: 'LY',
      countryName: 'Libya',
      currencyCode: 'LYD',
      population: '6461454',
      capital: 'Tripoli',
      continentName: 'Africa',
    },
    {
      countryCode: 'MA',
      countryName: 'Morocco',
      currencyCode: 'MAD',
      population: '33848242',
      capital: 'Rabat',
      continentName: 'Africa',
    },
    {
      countryCode: 'MC',
      countryName: 'Monaco',
      currencyCode: 'EUR',
      population: '32965',
      capital: 'Monaco',
      continentName: 'Europe',
    },
    {
      countryCode: 'MD',
      countryName: 'Moldova',
      currencyCode: 'MDL',
      population: '4324000',
      capital: 'Chişinău',
      continentName: 'Europe',
    },
    {
      countryCode: 'ME',
      countryName: 'Montenegro',
      currencyCode: 'EUR',
      population: '666730',
      capital: 'Podgorica',
      continentName: 'Europe',
    },
    {
      countryCode: 'MF',
      countryName: 'Saint Martin',
      currencyCode: 'EUR',
      population: '35925',
      capital: 'Marigot',
      continentName: 'North America',
    },
    {
      countryCode: 'MG',
      countryName: 'Madagascar',
      currencyCode: 'MGA',
      population: '21281844',
      capital: 'Antananarivo',
      continentName: 'Africa',
    },
    {
      countryCode: 'MH',
      countryName: 'Marshall Islands',
      currencyCode: 'USD',
      population: '65859',
      capital: 'Majuro',
      continentName: 'Oceania',
    },
    {
      countryCode: 'MK',
      countryName: 'Macedonia',
      currencyCode: 'MKD',
      population: '2062294',
      capital: 'Skopje',
      continentName: 'Europe',
    },
    {
      countryCode: 'ML',
      countryName: 'Mali',
      currencyCode: 'XOF',
      population: '13796354',
      capital: 'Bamako',
      continentName: 'Africa',
    },
    {
      countryCode: 'MM',
      countryName: 'Myanmar [Burma]',
      currencyCode: 'MMK',
      population: '53414374',
      capital: 'Naypyitaw',
      continentName: 'Asia',
    },
    {
      countryCode: 'MN',
      countryName: 'Mongolia',
      currencyCode: 'MNT',
      population: '3086918',
      capital: 'Ulan Bator',
      continentName: 'Asia',
    },
    {
      countryCode: 'MO',
      countryName: 'Macao',
      currencyCode: 'MOP',
      population: '449198',
      capital: 'Macao',
      continentName: 'Asia',
    },
    {
      countryCode: 'MP',
      countryName: 'Northern Mariana Islands',
      currencyCode: 'USD',
      population: '53883',
      capital: 'Saipan',
      continentName: 'Oceania',
    },
    {
      countryCode: 'MQ',
      countryName: 'Martinique',
      currencyCode: 'EUR',
      population: '432900',
      capital: 'Fort-de-France',
      continentName: 'North America',
    },
    {
      countryCode: 'MR',
      countryName: 'Mauritania',
      currencyCode: 'MRO',
      population: '3205060',
      capital: 'Nouakchott',
      continentName: 'Africa',
    },
    {
      countryCode: 'MS',
      countryName: 'Montserrat',
      currencyCode: 'XCD',
      population: '9341',
      capital: 'Plymouth',
      continentName: 'North America',
    },
    {
      countryCode: 'MT',
      countryName: 'Malta',
      currencyCode: 'EUR',
      population: '403000',
      capital: 'Valletta',
      continentName: 'Europe',
    },
    {
      countryCode: 'MU',
      countryName: 'Mauritius',
      currencyCode: 'MUR',
      population: '1294104',
      capital: 'Port Louis',
      continentName: 'Africa',
    },
    {
      countryCode: 'MV',
      countryName: 'Maldives',
      currencyCode: 'MVR',
      population: '395650',
      capital: 'Malé',
      continentName: 'Asia',
    },
    {
      countryCode: 'MW',
      countryName: 'Malawi',
      currencyCode: 'MWK',
      population: '15447500',
      capital: 'Lilongwe',
      continentName: 'Africa',
    },
    {
      countryCode: 'MX',
      countryName: 'Mexico',
      currencyCode: 'MXN',
      population: '112468855',
      capital: 'Mexico City',
      continentName: 'North America',
    },
    {
      countryCode: 'MY',
      countryName: 'Malaysia',
      currencyCode: 'MYR',
      population: '28274729',
      capital: 'Kuala Lumpur',
      continentName: 'Asia',
    },
    {
      countryCode: 'MZ',
      countryName: 'Mozambique',
      currencyCode: 'MZN',
      population: '22061451',
      capital: 'Maputo',
      continentName: 'Africa',
    },
    {
      countryCode: 'NA',
      countryName: 'Namibia',
      currencyCode: 'NAD',
      population: '2128471',
      capital: 'Windhoek',
      continentName: 'Africa',
    },
    {
      countryCode: 'NC',
      countryName: 'New Caledonia',
      currencyCode: 'XPF',
      population: '216494',
      capital: 'Noumea',
      continentName: 'Oceania',
    },
    {
      countryCode: 'NE',
      countryName: 'Niger',
      currencyCode: 'XOF',
      population: '15878271',
      capital: 'Niamey',
      continentName: 'Africa',
    },
    {
      countryCode: 'NF',
      countryName: 'Norfolk Island',
      currencyCode: 'AUD',
      population: '1828',
      capital: 'Kingston',
      continentName: 'Oceania',
    },
    {
      countryCode: 'NG',
      countryName: 'Nigeria',
      currencyCode: 'NGN',
      population: '154000000',
      capital: 'Abuja',
      continentName: 'Africa',
    },
    {
      countryCode: 'NI',
      countryName: 'Nicaragua',
      currencyCode: 'NIO',
      population: '5995928',
      capital: 'Managua',
      continentName: 'North America',
    },
    {
      countryCode: 'NL',
      countryName: 'Netherlands',
      currencyCode: 'EUR',
      population: '16645000',
      capital: 'Amsterdam',
      continentName: 'Europe',
    },
    {
      countryCode: 'NO',
      countryName: 'Norway',
      currencyCode: 'NOK',
      population: '5009150',
      capital: 'Oslo',
      continentName: 'Europe',
    },
    {
      countryCode: 'NP',
      countryName: 'Nepal',
      currencyCode: 'NPR',
      population: '28951852',
      capital: 'Kathmandu',
      continentName: 'Asia',
    },
    {
      countryCode: 'NR',
      countryName: 'Nauru',
      currencyCode: 'AUD',
      population: '10065',
      capital: 'Yaren',
      continentName: 'Oceania',
    },
    {
      countryCode: 'NU',
      countryName: 'Niue',
      currencyCode: 'NZD',
      population: '2166',
      capital: 'Alofi',
      continentName: 'Oceania',
    },
    {
      countryCode: 'NZ',
      countryName: 'New Zealand',
      currencyCode: 'NZD',
      population: '4252277',
      capital: 'Wellington',
      continentName: 'Oceania',
    },
    {
      countryCode: 'OM',
      countryName: 'Oman',
      currencyCode: 'OMR',
      population: '2967717',
      capital: 'Muscat',
      continentName: 'Asia',
    },
    {
      countryCode: 'PA',
      countryName: 'Panama',
      currencyCode: 'PAB',
      population: '3410676',
      capital: 'Panama City',
      continentName: 'North America',
    },
    {
      countryCode: 'PE',
      countryName: 'Peru',
      currencyCode: 'PEN',
      population: '29907003',
      capital: 'Lima',
      continentName: 'South America',
    },
    {
      countryCode: 'PF',
      countryName: 'French Polynesia',
      currencyCode: 'XPF',
      population: '270485',
      capital: 'Papeete',
      continentName: 'Oceania',
    },
    {
      countryCode: 'PG',
      countryName: 'Papua New Guinea',
      currencyCode: 'PGK',
      population: '6064515',
      capital: 'Port Moresby',
      continentName: 'Oceania',
    },
    {
      countryCode: 'PH',
      countryName: 'Philippines',
      currencyCode: 'PHP',
      population: '99900177',
      capital: 'Manila',
      continentName: 'Asia',
    },
    {
      countryCode: 'PK',
      countryName: 'Pakistan',
      currencyCode: 'PKR',
      population: '184404791',
      capital: 'Islamabad',
      continentName: 'Asia',
    },
    {
      countryCode: 'PL',
      countryName: 'Poland',
      currencyCode: 'PLN',
      population: '38500000',
      capital: 'Warsaw',
      continentName: 'Europe',
    },
    {
      countryCode: 'PM',
      countryName: 'Saint Pierre and Miquelon',
      currencyCode: 'EUR',
      population: '7012',
      capital: 'Saint-Pierre',
      continentName: 'North America',
    },
    {
      countryCode: 'PN',
      countryName: 'Pitcairn Islands',
      currencyCode: 'NZD',
      population: '46',
      capital: 'Adamstown',
      continentName: 'Oceania',
    },
    {
      countryCode: 'PR',
      countryName: 'Puerto Rico',
      currencyCode: 'USD',
      population: '3916632',
      capital: 'San Juan',
      continentName: 'North America',
    },
    {
      countryCode: 'PS',
      countryName: 'Palestine',
      currencyCode: 'ILS',
      population: '3800000',
      capital: '',
      continentName: 'Asia',
    },
    {
      countryCode: 'PT',
      countryName: 'Portugal',
      currencyCode: 'EUR',
      population: '10676000',
      capital: 'Lisbon',
      continentName: 'Europe',
    },
    {
      countryCode: 'PW',
      countryName: 'Palau',
      currencyCode: 'USD',
      population: '19907',
      capital: 'Melekeok',
      continentName: 'Oceania',
    },
    {
      countryCode: 'PY',
      countryName: 'Paraguay',
      currencyCode: 'PYG',
      population: '6375830',
      capital: 'Asunción',
      continentName: 'South America',
    },
    {
      countryCode: 'QA',
      countryName: 'Qatar',
      currencyCode: 'QAR',
      population: '840926',
      capital: 'Doha',
      continentName: 'Asia',
    },
    {
      countryCode: 'RE',
      countryName: 'Réunion',
      currencyCode: 'EUR',
      population: '776948',
      capital: 'Saint-Denis',
      continentName: 'Africa',
    },
    {
      countryCode: 'RO',
      countryName: 'Romania',
      currencyCode: 'RON',
      population: '21959278',
      capital: 'Bucharest',
      continentName: 'Europe',
    },
    {
      countryCode: 'RS',
      countryName: 'Serbia',
      currencyCode: 'RSD',
      population: '7344847',
      capital: 'Belgrade',
      continentName: 'Europe',
    },
    {
      countryCode: 'RU',
      countryName: 'Russia',
      currencyCode: 'RUB',
      population: '140702000',
      capital: 'Moscow',
      continentName: 'Europe',
    },
    {
      countryCode: 'RW',
      countryName: 'Rwanda',
      currencyCode: 'RWF',
      population: '11055976',
      capital: 'Kigali',
      continentName: 'Africa',
    },
    {
      countryCode: 'SA',
      countryName: 'Saudi Arabia',
      currencyCode: 'SAR',
      population: '25731776',
      capital: 'Riyadh',
      continentName: 'Asia',
    },
    {
      countryCode: 'SB',
      countryName: 'Solomon Islands',
      currencyCode: 'SBD',
      population: '559198',
      capital: 'Honiara',
      continentName: 'Oceania',
    },
    {
      countryCode: 'SC',
      countryName: 'Seychelles',
      currencyCode: 'SCR',
      population: '88340',
      capital: 'Victoria',
      continentName: 'Africa',
    },
    {
      countryCode: 'SD',
      countryName: 'Sudan',
      currencyCode: 'SDG',
      population: '35000000',
      capital: 'Khartoum',
      continentName: 'Africa',
    },
    {
      countryCode: 'SE',
      countryName: 'Sweden',
      currencyCode: 'SEK',
      population: '9828655',
      capital: 'Stockholm',
      continentName: 'Europe',
    },
    {
      countryCode: 'SG',
      countryName: 'Singapore',
      currencyCode: 'SGD',
      population: '4701069',
      capital: 'Singapore',
      continentName: 'Asia',
    },
    {
      countryCode: 'SH',
      countryName: 'Saint Helena',
      currencyCode: 'SHP',
      population: '7460',
      capital: 'Jamestown',
      continentName: 'Africa',
    },
    {
      countryCode: 'SI',
      countryName: 'Slovenia',
      currencyCode: 'EUR',
      population: '2007000',
      capital: 'Ljubljana',
      continentName: 'Europe',
    },
    {
      countryCode: 'SJ',
      countryName: 'Svalbard and Jan Mayen',
      currencyCode: 'NOK',
      population: '2550',
      capital: 'Longyearbyen',
      continentName: 'Europe',
    },
    {
      countryCode: 'SK',
      countryName: 'Slovakia',
      currencyCode: 'EUR',
      population: '5455000',
      capital: 'Bratislava',
      continentName: 'Europe',
    },
    {
      countryCode: 'SL',
      countryName: 'Sierra Leone',
      currencyCode: 'SLL',
      population: '5245695',
      capital: 'Freetown',
      continentName: 'Africa',
    },
    {
      countryCode: 'SM',
      countryName: 'San Marino',
      currencyCode: 'EUR',
      population: '31477',
      capital: 'San Marino',
      continentName: 'Europe',
    },
    {
      countryCode: 'SN',
      countryName: 'Senegal',
      currencyCode: 'XOF',
      population: '12323252',
      capital: 'Dakar',
      continentName: 'Africa',
    },
    {
      countryCode: 'SO',
      countryName: 'Somalia',
      currencyCode: 'SOS',
      population: '10112453',
      capital: 'Mogadishu',
      continentName: 'Africa',
    },
    {
      countryCode: 'SR',
      countryName: 'Suriname',
      currencyCode: 'SRD',
      population: '492829',
      capital: 'Paramaribo',
      continentName: 'South America',
    },
    {
      countryCode: 'SS',
      countryName: 'South Sudan',
      currencyCode: 'SSP',
      population: '8260490',
      capital: 'Juba',
      continentName: 'Africa',
    },
    {
      countryCode: 'ST',
      countryName: 'São Tomé and Príncipe',
      currencyCode: 'STD',
      population: '175808',
      capital: 'São Tomé',
      continentName: 'Africa',
    },
    {
      countryCode: 'SV',
      countryName: 'El Salvador',
      currencyCode: 'USD',
      population: '6052064',
      capital: 'San Salvador',
      continentName: 'North America',
    },
    {
      countryCode: 'SX',
      countryName: 'Sint Maarten',
      currencyCode: 'ANG',
      population: '37429',
      capital: 'Philipsburg',
      continentName: 'North America',
    },
    {
      countryCode: 'SY',
      countryName: 'Syria',
      currencyCode: 'SYP',
      population: '22198110',
      capital: 'Damascus',
      continentName: 'Asia',
    },
    {
      countryCode: 'SZ',
      countryName: 'Swaziland',
      currencyCode: 'SZL',
      population: '1354051',
      capital: 'Mbabane',
      continentName: 'Africa',
    },
    {
      countryCode: 'TC',
      countryName: 'Turks and Caicos Islands',
      currencyCode: 'USD',
      population: '20556',
      capital: 'Cockburn Town',
      continentName: 'North America',
    },
    {
      countryCode: 'TD',
      countryName: 'Chad',
      currencyCode: 'XAF',
      population: '10543464',
      capital: "N'Djamena",
      continentName: 'Africa',
    },
    {
      countryCode: 'TF',
      countryName: 'French Southern Territories',
      currencyCode: 'EUR',
      population: '140',
      capital: 'Port-aux-Français',
      continentName: 'Antarctica',
    },
    {
      countryCode: 'TG',
      countryName: 'Togo',
      currencyCode: 'XOF',
      population: '6587239',
      capital: 'Lomé',
      continentName: 'Africa',
    },
    {
      countryCode: 'TH',
      countryName: 'Thailand',
      currencyCode: 'THB',
      population: '67089500',
      capital: 'Bangkok',
      continentName: 'Asia',
    },
    {
      countryCode: 'TJ',
      countryName: 'Tajikistan',
      currencyCode: 'TJS',
      population: '7487489',
      capital: 'Dushanbe',
      continentName: 'Asia',
    },
    {
      countryCode: 'TK',
      countryName: 'Tokelau',
      currencyCode: 'NZD',
      population: '1466',
      capital: '',
      continentName: 'Oceania',
    },
    {
      countryCode: 'TL',
      countryName: 'East Timor',
      currencyCode: 'USD',
      population: '1154625',
      capital: 'Dili',
      continentName: 'Oceania',
    },
    {
      countryCode: 'TM',
      countryName: 'Turkmenistan',
      currencyCode: 'TMT',
      population: '4940916',
      capital: 'Ashgabat',
      continentName: 'Asia',
    },
    {
      countryCode: 'TN',
      countryName: 'Tunisia',
      currencyCode: 'TND',
      population: '10589025',
      capital: 'Tunis',
      continentName: 'Africa',
    },
    {
      countryCode: 'TO',
      countryName: 'Tonga',
      currencyCode: 'TOP',
      population: '122580',
      capital: "Nuku'alofa",
      continentName: 'Oceania',
    },
    {
      countryCode: 'TR',
      countryName: 'Turkey',
      currencyCode: 'TRY',
      population: '77804122',
      capital: 'Ankara',
      continentName: 'Asia',
    },
    {
      countryCode: 'TT',
      countryName: 'Trinidad and Tobago',
      currencyCode: 'TTD',
      population: '1228691',
      capital: 'Port of Spain',
      continentName: 'North America',
    },
    {
      countryCode: 'TV',
      countryName: 'Tuvalu',
      currencyCode: 'AUD',
      population: '10472',
      capital: 'Funafuti',
      continentName: 'Oceania',
    },
    {
      countryCode: 'TW',
      countryName: 'Taiwan',
      currencyCode: 'TWD',
      population: '22894384',
      capital: 'Taipei',
      continentName: 'Asia',
    },
    {
      countryCode: 'TZ',
      countryName: 'Tanzania',
      currencyCode: 'TZS',
      population: '41892895',
      capital: 'Dodoma',
      continentName: 'Africa',
    },
    {
      countryCode: 'UA',
      countryName: 'Ukraine',
      currencyCode: 'UAH',
      population: '45415596',
      capital: 'Kiev',
      continentName: 'Europe',
    },
    {
      countryCode: 'UG',
      countryName: 'Uganda',
      currencyCode: 'UGX',
      population: '33398682',
      capital: 'Kampala',
      continentName: 'Africa',
    },
    {
      countryCode: 'UM',
      countryName: 'U.S. Minor Outlying Islands',
      currencyCode: 'USD',
      population: '0',
      capital: '',
      continentName: 'Oceania',
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      currencyCode: 'USD',
      population: '310232863',
      capital: 'Washington',
      continentName: 'North America',
    },
    {
      countryCode: 'UY',
      countryName: 'Uruguay',
      currencyCode: 'UYU',
      population: '3477000',
      capital: 'Montevideo',
      continentName: 'South America',
    },
    {
      countryCode: 'UZ',
      countryName: 'Uzbekistan',
      currencyCode: 'UZS',
      population: '27865738',
      capital: 'Tashkent',
      continentName: 'Asia',
    },
    {
      countryCode: 'VA',
      countryName: 'Vatican City',
      currencyCode: 'EUR',
      population: '921',
      capital: 'Vatican City',
      continentName: 'Europe',
    },
    {
      countryCode: 'VC',
      countryName: 'Saint Vincent and the Grenadines',
      currencyCode: 'XCD',
      population: '104217',
      capital: 'Kingstown',
      continentName: 'North America',
    },
    {
      countryCode: 'VE',
      countryName: 'Venezuela',
      currencyCode: 'VEF',
      population: '27223228',
      capital: 'Caracas',
      continentName: 'South America',
    },
    {
      countryCode: 'VG',
      countryName: 'British Virgin Islands',
      currencyCode: 'USD',
      population: '21730',
      capital: 'Road Town',
      continentName: 'North America',
    },
    {
      countryCode: 'VI',
      countryName: 'U.S. Virgin Islands',
      currencyCode: 'USD',
      population: '108708',
      capital: 'Charlotte Amalie',
      continentName: 'North America',
    },
    {
      countryCode: 'VN',
      countryName: 'Vietnam',
      currencyCode: 'VND',
      population: '89571130',
      capital: 'Hanoi',
      continentName: 'Asia',
    },
    {
      countryCode: 'VU',
      countryName: 'Vanuatu',
      currencyCode: 'VUV',
      population: '221552',
      capital: 'Port Vila',
      continentName: 'Oceania',
    },
    {
      countryCode: 'WF',
      countryName: 'Wallis and Futuna',
      currencyCode: 'XPF',
      population: '16025',
      capital: 'Mata-Utu',
      continentName: 'Oceania',
    },
    {
      countryCode: 'WS',
      countryName: 'Samoa',
      currencyCode: 'WST',
      population: '192001',
      capital: 'Apia',
      continentName: 'Oceania',
    },
    {
      countryCode: 'XK',
      countryName: 'Kosovo',
      currencyCode: 'EUR',
      population: '1800000',
      capital: 'Pristina',
      continentName: 'Europe',
    },
    {
      countryCode: 'YE',
      countryName: 'Yemen',
      currencyCode: 'YER',
      population: '23495361',
      capital: 'Sanaa',
      continentName: 'Asia',
    },
    {
      countryCode: 'YT',
      countryName: 'Mayotte',
      currencyCode: 'EUR',
      population: '159042',
      capital: 'Mamoudzou',
      continentName: 'Africa',
    },
    {
      countryCode: 'ZA',
      countryName: 'South Africa',
      currencyCode: 'ZAR',
      population: '49000000',
      capital: 'Pretoria',
      continentName: 'Africa',
    },
    {
      countryCode: 'ZM',
      countryName: 'Zambia',
      currencyCode: 'ZMW',
      population: '13460305',
      capital: 'Lusaka',
      continentName: 'Africa',
    },
    {
      countryCode: 'ZW',
      countryName: 'Zimbabwe',
      currencyCode: 'ZWL',
      population: '13061000',
      capital: 'Harare',
      continentName: 'Africa',
    },
  ];

  phoneCodeDetails = [
    {
      "name": "Afghanistan",
      "dial_code": "+93",
      "code": "AF"
    },
    {
      "name": "Aland Islands",
      "dial_code": "+358",
      "code": "AX"
    },
    {
      "name": "Albania",
      "dial_code": "+355",
      "code": "AL"
    },
    {
      "name": "Algeria",
      "dial_code": "+213",
      "code": "DZ"
    },
    {
      "name": "AmericanSamoa",
      "dial_code": "+1684",
      "code": "AS"
    },
    {
      "name": "Andorra",
      "dial_code": "+376",
      "code": "AD"
    },
    {
      "name": "Angola",
      "dial_code": "+244",
      "code": "AO"
    },
    {
      "name": "Anguilla",
      "dial_code": "+1264",
      "code": "AI"
    },
    {
      "name": "Antarctica",
      "dial_code": "+672",
      "code": "AQ"
    },
    {
      "name": "Antigua and Barbuda",
      "dial_code": "+1268",
      "code": "AG"
    },
    {
      "name": "Argentina",
      "dial_code": "+54",
      "code": "AR"
    },
    {
      "name": "Armenia",
      "dial_code": "+374",
      "code": "AM"
    },
    {
      "name": "Aruba",
      "dial_code": "+297",
      "code": "AW"
    },
    {
      "name": "Australia",
      "dial_code": "+61",
      "code": "AU"
    },
    {
      "name": "Austria",
      "dial_code": "+43",
      "code": "AT"
    },
    {
      "name": "Azerbaijan",
      "dial_code": "+994",
      "code": "AZ"
    },
    {
      "name": "Bahamas",
      "dial_code": "+1242",
      "code": "BS"
    },
    {
      "name": "Bahrain",
      "dial_code": "+973",
      "code": "BH"
    },
    {
      "name": "Bangladesh",
      "dial_code": "+880",
      "code": "BD"
    },
    {
      "name": "Barbados",
      "dial_code": "+1246",
      "code": "BB"
    },
    {
      "name": "Belarus",
      "dial_code": "+375",
      "code": "BY"
    },
    {
      "name": "Belgium",
      "dial_code": "+32",
      "code": "BE"
    },
    {
      "name": "Belize",
      "dial_code": "+501",
      "code": "BZ"
    },
    {
      "name": "Benin",
      "dial_code": "+229",
      "code": "BJ"
    },
    {
      "name": "Bermuda",
      "dial_code": "+1441",
      "code": "BM"
    },
    {
      "name": "Bhutan",
      "dial_code": "+975",
      "code": "BT"
    },
    {
      "name": "Bolivia, Plurinational State of",
      "dial_code": "+591",
      "code": "BO"
    },
    {
      "name": "Bosnia and Herzegovina",
      "dial_code": "+387",
      "code": "BA"
    },
    {
      "name": "Botswana",
      "dial_code": "+267",
      "code": "BW"
    },
    {
      "name": "Brazil",
      "dial_code": "+55",
      "code": "BR"
    },
    {
      "name": "British Indian Ocean Territory",
      "dial_code": "+246",
      "code": "IO"
    },
    {
      "name": "Brunei Darussalam",
      "dial_code": "+673",
      "code": "BN"
    },
    {
      "name": "Bulgaria",
      "dial_code": "+359",
      "code": "BG"
    },
    {
      "name": "Burkina Faso",
      "dial_code": "+226",
      "code": "BF"
    },
    {
      "name": "Burundi",
      "dial_code": "+257",
      "code": "BI"
    },
    {
      "name": "Cambodia",
      "dial_code": "+855",
      "code": "KH"
    },
    {
      "name": "Cameroon",
      "dial_code": "+237",
      "code": "CM"
    },
    {
      "name": "Canada",
      "dial_code": "+1",
      "code": "CA"
    },
    {
      "name": "Cape Verde",
      "dial_code": "+238",
      "code": "CV"
    },
    {
      "name": "Cayman Islands",
      "dial_code": "+ 345",
      "code": "KY"
    },
    {
      "name": "Central African Republic",
      "dial_code": "+236",
      "code": "CF"
    },
    {
      "name": "Chad",
      "dial_code": "+235",
      "code": "TD"
    },
    {
      "name": "Chile",
      "dial_code": "+56",
      "code": "CL"
    },
    {
      "name": "China",
      "dial_code": "+86",
      "code": "CN"
    },
    {
      "name": "Christmas Island",
      "dial_code": "+61",
      "code": "CX"
    },
    {
      "name": "Cocos (Keeling) Islands",
      "dial_code": "+61",
      "code": "CC"
    },
    {
      "name": "Colombia",
      "dial_code": "+57",
      "code": "CO"
    },
    {
      "name": "Comoros",
      "dial_code": "+269",
      "code": "KM"
    },
    {
      "name": "Congo",
      "dial_code": "+242",
      "code": "CG"
    },
    {
      "name": "Congo, The Democratic Republic of the Congo",
      "dial_code": "+243",
      "code": "CD"
    },
    {
      "name": "Cook Islands",
      "dial_code": "+682",
      "code": "CK"
    },
    {
      "name": "Costa Rica",
      "dial_code": "+506",
      "code": "CR"
    },
    {
      "name": "Cote d'Ivoire",
      "dial_code": "+225",
      "code": "CI"
    },
    {
      "name": "Croatia",
      "dial_code": "+385",
      "code": "HR"
    },
    {
      "name": "Cuba",
      "dial_code": "+53",
      "code": "CU"
    },
    {
      "name": "Cyprus",
      "dial_code": "+357",
      "code": "CY"
    },
    {
      "name": "Czech Republic",
      "dial_code": "+420",
      "code": "CZ"
    },
    {
      "name": "Denmark",
      "dial_code": "+45",
      "code": "DK"
    },
    {
      "name": "Djibouti",
      "dial_code": "+253",
      "code": "DJ"
    },
    {
      "name": "Dominica",
      "dial_code": "+1767",
      "code": "DM"
    },
    {
      "name": "Dominican Republic",
      "dial_code": "+1849",
      "code": "DO"
    },
    {
      "name": "Ecuador",
      "dial_code": "+593",
      "code": "EC"
    },
    {
      "name": "Egypt",
      "dial_code": "+20",
      "code": "EG"
    },
    {
      "name": "El Salvador",
      "dial_code": "+503",
      "code": "SV"
    },
    {
      "name": "Equatorial Guinea",
      "dial_code": "+240",
      "code": "GQ"
    },
    {
      "name": "Eritrea",
      "dial_code": "+291",
      "code": "ER"
    },
    {
      "name": "Estonia",
      "dial_code": "+372",
      "code": "EE"
    },
    {
      "name": "Ethiopia",
      "dial_code": "+251",
      "code": "ET"
    },
    {
      "name": "Falkland Islands (Malvinas)",
      "dial_code": "+500",
      "code": "FK"
    },
    {
      "name": "Faroe Islands",
      "dial_code": "+298",
      "code": "FO"
    },
    {
      "name": "Fiji",
      "dial_code": "+679",
      "code": "FJ"
    },
    {
      "name": "Finland",
      "dial_code": "+358",
      "code": "FI"
    },
    {
      "name": "France",
      "dial_code": "+33",
      "code": "FR"
    },
    {
      "name": "French Guiana",
      "dial_code": "+594",
      "code": "GF"
    },
    {
      "name": "French Polynesia",
      "dial_code": "+689",
      "code": "PF"
    },
    {
      "name": "Gabon",
      "dial_code": "+241",
      "code": "GA"
    },
    {
      "name": "Gambia",
      "dial_code": "+220",
      "code": "GM"
    },
    {
      "name": "Georgia",
      "dial_code": "+995",
      "code": "GE"
    },
    {
      "name": "Germany",
      "dial_code": "+49",
      "code": "DE"
    },
    {
      "name": "Ghana",
      "dial_code": "+233",
      "code": "GH"
    },
    {
      "name": "Gibraltar",
      "dial_code": "+350",
      "code": "GI"
    },
    {
      "name": "Greece",
      "dial_code": "+30",
      "code": "GR"
    },
    {
      "name": "Greenland",
      "dial_code": "+299",
      "code": "GL"
    },
    {
      "name": "Grenada",
      "dial_code": "+1473",
      "code": "GD"
    },
    {
      "name": "Guadeloupe",
      "dial_code": "+590",
      "code": "GP"
    },
    {
      "name": "Guam",
      "dial_code": "+1671",
      "code": "GU"
    },
    {
      "name": "Guatemala",
      "dial_code": "+502",
      "code": "GT"
    },
    {
      "name": "Guernsey",
      "dial_code": "+44",
      "code": "GG"
    },
    {
      "name": "Guinea",
      "dial_code": "+224",
      "code": "GN"
    },
    {
      "name": "Guinea-Bissau",
      "dial_code": "+245",
      "code": "GW"
    },
    {
      "name": "Guyana",
      "dial_code": "+595",
      "code": "GY"
    },
    {
      "name": "Haiti",
      "dial_code": "+509",
      "code": "HT"
    },
    {
      "name": "Holy See (Vatican City State)",
      "dial_code": "+379",
      "code": "VA"
    },
    {
      "name": "Honduras",
      "dial_code": "+504",
      "code": "HN"
    },
    {
      "name": "Hong Kong",
      "dial_code": "+852",
      "code": "HK"
    },
    {
      "name": "Hungary",
      "dial_code": "+36",
      "code": "HU"
    },
    {
      "name": "Iceland",
      "dial_code": "+354",
      "code": "IS"
    },
    {
      "name": "India",
      "dial_code": "+91",
      "code": "IN"
    },
    {
      "name": "Indonesia",
      "dial_code": "+62",
      "code": "ID"
    },
    {
      "name": "Iran, Islamic Republic of Persian Gulf",
      "dial_code": "+98",
      "code": "IR"
    },
    {
      "name": "Iraq",
      "dial_code": "+964",
      "code": "IQ"
    },
    {
      "name": "Ireland",
      "dial_code": "+353",
      "code": "IE"
    },
    {
      "name": "Isle of Man",
      "dial_code": "+44",
      "code": "IM"
    },
    {
      "name": "Israel",
      "dial_code": "+972",
      "code": "IL"
    },
    {
      "name": "Italy",
      "dial_code": "+39",
      "code": "IT"
    },
    {
      "name": "Jamaica",
      "dial_code": "+1876",
      "code": "JM"
    },
    {
      "name": "Japan",
      "dial_code": "+81",
      "code": "JP"
    },
    {
      "name": "Jersey",
      "dial_code": "+44",
      "code": "JE"
    },
    {
      "name": "Jordan",
      "dial_code": "+962",
      "code": "JO"
    },
    {
      "name": "Kazakhstan",
      "dial_code": "+77",
      "code": "KZ"
    },
    {
      "name": "Kenya",
      "dial_code": "+254",
      "code": "KE"
    },
    {
      "name": "Kiribati",
      "dial_code": "+686",
      "code": "KI"
    },
    {
      "name": "Korea, Democratic People's Republic of Korea",
      "dial_code": "+850",
      "code": "KP"
    },
    {
      "name": "Korea, Republic of South Korea",
      "dial_code": "+82",
      "code": "KR"
    },
    {
      "name": "Kuwait",
      "dial_code": "+965",
      "code": "KW"
    },
    {
      "name": "Kyrgyzstan",
      "dial_code": "+996",
      "code": "KG"
    },
    {
      "name": "Laos",
      "dial_code": "+856",
      "code": "LA"
    },
    {
      "name": "Latvia",
      "dial_code": "+371",
      "code": "LV"
    },
    {
      "name": "Lebanon",
      "dial_code": "+961",
      "code": "LB"
    },
    {
      "name": "Lesotho",
      "dial_code": "+266",
      "code": "LS"
    },
    {
      "name": "Liberia",
      "dial_code": "+231",
      "code": "LR"
    },
    {
      "name": "Libyan Arab Jamahiriya",
      "dial_code": "+218",
      "code": "LY"
    },
    {
      "name": "Liechtenstein",
      "dial_code": "+423",
      "code": "LI"
    },
    {
      "name": "Lithuania",
      "dial_code": "+370",
      "code": "LT"
    },
    {
      "name": "Luxembourg",
      "dial_code": "+352",
      "code": "LU"
    },
    {
      "name": "Macao",
      "dial_code": "+853",
      "code": "MO"
    },
    {
      "name": "Macedonia",
      "dial_code": "+389",
      "code": "MK"
    },
    {
      "name": "Madagascar",
      "dial_code": "+261",
      "code": "MG"
    },
    {
      "name": "Malawi",
      "dial_code": "+265",
      "code": "MW"
    },
    {
      "name": "Malaysia",
      "dial_code": "+60",
      "code": "MY"
    },
    {
      "name": "Maldives",
      "dial_code": "+960",
      "code": "MV"
    },
    {
      "name": "Mali",
      "dial_code": "+223",
      "code": "ML"
    },
    {
      "name": "Malta",
      "dial_code": "+356",
      "code": "MT"
    },
    {
      "name": "Marshall Islands",
      "dial_code": "+692",
      "code": "MH"
    },
    {
      "name": "Martinique",
      "dial_code": "+596",
      "code": "MQ"
    },
    {
      "name": "Mauritania",
      "dial_code": "+222",
      "code": "MR"
    },
    {
      "name": "Mauritius",
      "dial_code": "+230",
      "code": "MU"
    },
    {
      "name": "Mayotte",
      "dial_code": "+262",
      "code": "YT"
    },
    {
      "name": "Mexico",
      "dial_code": "+52",
      "code": "MX"
    },
    {
      "name": "Micronesia, Federated States of Micronesia",
      "dial_code": "+691",
      "code": "FM"
    },
    {
      "name": "Moldova",
      "dial_code": "+373",
      "code": "MD"
    },
    {
      "name": "Monaco",
      "dial_code": "+377",
      "code": "MC"
    },
    {
      "name": "Mongolia",
      "dial_code": "+976",
      "code": "MN"
    },
    {
      "name": "Montenegro",
      "dial_code": "+382",
      "code": "ME"
    },
    {
      "name": "Montserrat",
      "dial_code": "+1664",
      "code": "MS"
    },
    {
      "name": "Morocco",
      "dial_code": "+212",
      "code": "MA"
    },
    {
      "name": "Mozambique",
      "dial_code": "+258",
      "code": "MZ"
    },
    {
      "name": "Myanmar",
      "dial_code": "+95",
      "code": "MM"
    },
    {
      "name": "Namibia",
      "dial_code": "+264",
      "code": "NA"
    },
    {
      "name": "Nauru",
      "dial_code": "+674",
      "code": "NR"
    },
    {
      "name": "Nepal",
      "dial_code": "+977",
      "code": "NP"
    },
    {
      "name": "Netherlands",
      "dial_code": "+31",
      "code": "NL"
    },
    {
      "name": "Netherlands Antilles",
      "dial_code": "+599",
      "code": "AN"
    },
    {
      "name": "New Caledonia",
      "dial_code": "+687",
      "code": "NC"
    },
    {
      "name": "New Zealand",
      "dial_code": "+64",
      "code": "NZ"
    },
    {
      "name": "Nicaragua",
      "dial_code": "+505",
      "code": "NI"
    },
    {
      "name": "Niger",
      "dial_code": "+227",
      "code": "NE"
    },
    {
      "name": "Nigeria",
      "dial_code": "+234",
      "code": "NG"
    },
    {
      "name": "Niue",
      "dial_code": "+683",
      "code": "NU"
    },
    {
      "name": "Norfolk Island",
      "dial_code": "+672",
      "code": "NF"
    },
    {
      "name": "Northern Mariana Islands",
      "dial_code": "+1670",
      "code": "MP"
    },
    {
      "name": "Norway",
      "dial_code": "+47",
      "code": "NO"
    },
    {
      "name": "Oman",
      "dial_code": "+968",
      "code": "OM"
    },
    {
      "name": "Pakistan",
      "dial_code": "+92",
      "code": "PK"
    },
    {
      "name": "Palau",
      "dial_code": "+680",
      "code": "PW"
    },
    {
      "name": "Palestinian Territory, Occupied",
      "dial_code": "+970",
      "code": "PS"
    },
    {
      "name": "Panama",
      "dial_code": "+507",
      "code": "PA"
    },
    {
      "name": "Papua New Guinea",
      "dial_code": "+675",
      "code": "PG"
    },
    {
      "name": "Paraguay",
      "dial_code": "+595",
      "code": "PY"
    },
    {
      "name": "Peru",
      "dial_code": "+51",
      "code": "PE"
    },
    {
      "name": "Philippines",
      "dial_code": "+63",
      "code": "PH"
    },
    {
      "name": "Pitcairn",
      "dial_code": "+872",
      "code": "PN"
    },
    {
      "name": "Poland",
      "dial_code": "+48",
      "code": "PL"
    },
    {
      "name": "Portugal",
      "dial_code": "+351",
      "code": "PT"
    },
    {
      "name": "Puerto Rico",
      "dial_code": "+1939",
      "code": "PR"
    },
    {
      "name": "Qatar",
      "dial_code": "+974",
      "code": "QA"
    },
    {
      "name": "Romania",
      "dial_code": "+40",
      "code": "RO"
    },
    {
      "name": "Russia",
      "dial_code": "+7",
      "code": "RU"
    },
    {
      "name": "Rwanda",
      "dial_code": "+250",
      "code": "RW"
    },
    {
      "name": "Reunion",
      "dial_code": "+262",
      "code": "RE"
    },
    {
      "name": "Saint Barthelemy",
      "dial_code": "+590",
      "code": "BL"
    },
    {
      "name": "Saint Helena, Ascension and Tristan Da Cunha",
      "dial_code": "+290",
      "code": "SH"
    },
    {
      "name": "Saint Kitts and Nevis",
      "dial_code": "+1869",
      "code": "KN"
    },
    {
      "name": "Saint Lucia",
      "dial_code": "+1758",
      "code": "LC"
    },
    {
      "name": "Saint Martin",
      "dial_code": "+590",
      "code": "MF"
    },
    {
      "name": "Saint Pierre and Miquelon",
      "dial_code": "+508",
      "code": "PM"
    },
    {
      "name": "Saint Vincent and the Grenadines",
      "dial_code": "+1784",
      "code": "VC"
    },
    {
      "name": "Samoa",
      "dial_code": "+685",
      "code": "WS"
    },
    {
      "name": "San Marino",
      "dial_code": "+378",
      "code": "SM"
    },
    {
      "name": "Sao Tome and Principe",
      "dial_code": "+239",
      "code": "ST"
    },
    {
      "name": "Saudi Arabia",
      "dial_code": "+966",
      "code": "SA"
    },
    {
      "name": "Senegal",
      "dial_code": "+221",
      "code": "SN"
    },
    {
      "name": "Serbia",
      "dial_code": "+381",
      "code": "RS"
    },
    {
      "name": "Seychelles",
      "dial_code": "+248",
      "code": "SC"
    },
    {
      "name": "Sierra Leone",
      "dial_code": "+232",
      "code": "SL"
    },
    {
      "name": "Singapore",
      "dial_code": "+65",
      "code": "SG"
    },
    {
      "name": "Slovakia",
      "dial_code": "+421",
      "code": "SK"
    },
    {
      "name": "Slovenia",
      "dial_code": "+386",
      "code": "SI"
    },
    {
      "name": "Solomon Islands",
      "dial_code": "+677",
      "code": "SB"
    },
    {
      "name": "Somalia",
      "dial_code": "+252",
      "code": "SO"
    },
    {
      "name": "South Africa",
      "dial_code": "+27",
      "code": "ZA"
    },
    {
      "name": "South Sudan",
      "dial_code": "+211",
      "code": "SS"
    },
    {
      "name": "South Georgia and the South Sandwich Islands",
      "dial_code": "+500",
      "code": "GS"
    },
    {
      "name": "Spain",
      "dial_code": "+34",
      "code": "ES"
    },
    {
      "name": "Sri Lanka",
      "dial_code": "+94",
      "code": "LK"
    },
    {
      "name": "Sudan",
      "dial_code": "+249",
      "code": "SD"
    },
    {
      "name": "Suriname",
      "dial_code": "+597",
      "code": "SR"
    },
    {
      "name": "Svalbard and Jan Mayen",
      "dial_code": "+47",
      "code": "SJ"
    },
    {
      "name": "Swaziland",
      "dial_code": "+268",
      "code": "SZ"
    },
    {
      "name": "Sweden",
      "dial_code": "+46",
      "code": "SE"
    },
    {
      "name": "Switzerland",
      "dial_code": "+41",
      "code": "CH"
    },
    {
      "name": "Syrian Arab Republic",
      "dial_code": "+963",
      "code": "SY"
    },
    {
      "name": "Taiwan",
      "dial_code": "+886",
      "code": "TW"
    },
    {
      "name": "Tajikistan",
      "dial_code": "+992",
      "code": "TJ"
    },
    {
      "name": "Tanzania, United Republic of Tanzania",
      "dial_code": "+255",
      "code": "TZ"
    },
    {
      "name": "Thailand",
      "dial_code": "+66",
      "code": "TH"
    },
    {
      "name": "Timor-Leste",
      "dial_code": "+670",
      "code": "TL"
    },
    {
      "name": "Togo",
      "dial_code": "+228",
      "code": "TG"
    },
    {
      "name": "Tokelau",
      "dial_code": "+690",
      "code": "TK"
    },
    {
      "name": "Tonga",
      "dial_code": "+676",
      "code": "TO"
    },
    {
      "name": "Trinidad and Tobago",
      "dial_code": "+1868",
      "code": "TT"
    },
    {
      "name": "Tunisia",
      "dial_code": "+216",
      "code": "TN"
    },
    {
      "name": "Turkey",
      "dial_code": "+90",
      "code": "TR"
    },
    {
      "name": "Turkmenistan",
      "dial_code": "+993",
      "code": "TM"
    },
    {
      "name": "Turks and Caicos Islands",
      "dial_code": "+1649",
      "code": "TC"
    },
    {
      "name": "Tuvalu",
      "dial_code": "+688",
      "code": "TV"
    },
    {
      "name": "Uganda",
      "dial_code": "+256",
      "code": "UG"
    },
    {
      "name": "Ukraine",
      "dial_code": "+380",
      "code": "UA"
    },
    {
      "name": "United Arab Emirates",
      "dial_code": "+971",
      "code": "AE"
    },
    {
      "name": "United Kingdom",
      "dial_code": "+44",
      "code": "GB"
    },
    {
      "name": "United States",
      "dial_code": "+1",
      "code": "US"
    },
    {
      "name": "Uruguay",
      "dial_code": "+598",
      "code": "UY"
    },
    {
      "name": "Uzbekistan",
      "dial_code": "+998",
      "code": "UZ"
    },
    {
      "name": "Vanuatu",
      "dial_code": "+678",
      "code": "VU"
    },
    {
      "name": "Venezuela, Bolivarian Republic of Venezuela",
      "dial_code": "+58",
      "code": "VE"
    },
    {
      "name": "Vietnam",
      "dial_code": "+84",
      "code": "VN"
    },
    {
      "name": "Virgin Islands, British",
      "dial_code": "+1284",
      "code": "VG"
    },
    {
      "name": "Virgin Islands, U.S.",
      "dial_code": "+1340",
      "code": "VI"
    },
    {
      "name": "Wallis and Futuna",
      "dial_code": "+681",
      "code": "WF"
    },
    {
      "name": "Yemen",
      "dial_code": "+967",
      "code": "YE"
    },
    {
      "name": "Zambia",
      "dial_code": "+260",
      "code": "ZM"
    },
    {
      "name": "Zimbabwe",
      "dial_code": "+263",
      "code": "ZW"
    }
  ]

}
