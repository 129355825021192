import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CommonServiceService } from 'src/app/Services/common-service.service';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  constructor(private route:ActivatedRoute,private service:CommonServiceService,private router: Router,private location: Location) { }
  roll: any
  pathUrl : any
  ids:any
  urlVal:any
  returnRedirectBroadcastInfo : boolean = false
  returnRedirectUserPackage : boolean = false
  returnRedirectToExpireToken : boolean = false
  returnRedirectToSendFeatureList : boolean = false
  returnRedirectToAdminQuery : boolean = false
  returnRedirectToPaymentFailure : boolean = false
  returnRedirectToPackageExpireUser : boolean = false

  ngOnInit(): void {
    let islog: any = localStorage.getItem('possession')
    this.roll = islog    
    if(this.router.events){
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
        this.pathUrl = event.url
        let checkRedirect :any = this.location.getState()        
        if((checkRedirect && checkRedirect.returnRedirectBroadcastInfo != '' && checkRedirect.returnRedirectBroadcastInfo != undefined && checkRedirect.returnRedirectBroadcastInfo != null)){ //redirectToBroadcastInfo
          this.returnRedirectBroadcastInfo = checkRedirect.returnRedirectBroadcastInfo
        }else if(checkRedirect && checkRedirect.redirectToBroadcastInfo != '' && checkRedirect.redirectToBroadcastInfo != undefined && checkRedirect.redirectToBroadcastInfo != null){
          this.returnRedirectBroadcastInfo = checkRedirect.redirectToBroadcastInfo
        }else{
          this.returnRedirectBroadcastInfo = false
        }
        if(checkRedirect && checkRedirect.redirectUserPackage != '' && checkRedirect.redirectUserPackage != undefined && checkRedirect.redirectUserPackage != null){
          this.returnRedirectUserPackage = checkRedirect.redirectUserPackage
        }else{
          this.returnRedirectUserPackage = false
        }
        if(checkRedirect && checkRedirect.redirectToExpireToken != '' && checkRedirect.redirectToExpireToken != undefined && checkRedirect.redirectToExpireToken != null){
          this.returnRedirectToExpireToken = checkRedirect.redirectToExpireToken
        }else{
          this.returnRedirectToExpireToken = false
        }
        if(checkRedirect && checkRedirect.redirectToSendFeatureList != '' && checkRedirect.redirectToSendFeatureList != undefined && checkRedirect.redirectToSendFeatureList != null){
          this.returnRedirectToSendFeatureList = checkRedirect.redirectToSendFeatureList
        }else{
          this.returnRedirectToSendFeatureList = false
        }
        if(checkRedirect && checkRedirect.redirectToAdminQuery != '' && checkRedirect.redirectToAdminQuery != undefined && checkRedirect.redirectToAdminQuery != null){
          this.returnRedirectToAdminQuery = checkRedirect.redirectToAdminQuery
        }else{
          this.returnRedirectToAdminQuery = false
        }
        if(checkRedirect && checkRedirect.redirectToPaymentFailure != '' && checkRedirect.redirectToPaymentFailure != undefined && checkRedirect.redirectToPaymentFailure != null){
          this.returnRedirectToPaymentFailure = checkRedirect.redirectToPaymentFailure
        }else{
          this.returnRedirectToPaymentFailure = false
        }
        if(checkRedirect && checkRedirect.redirectToPackageExpireUser != '' && checkRedirect.redirectToPackageExpireUser != undefined && checkRedirect.redirectToPackageExpireUser != null){
          this.returnRedirectToPackageExpireUser = checkRedirect.redirectToPackageExpireUser
        }else{
          this.returnRedirectToPackageExpireUser = false
        }
        
        //const isSubstringPresent = this.pathUrl == ("/superadmin/business-user-detail");
        if(this.pathUrl.includes("/superadmin/business-user-detail")){
          this.pathUrl = "/superadmin/business-user-detail"
        } else if(this.pathUrl.includes("/superadmin/package-history-details")){
          this.pathUrl = "/superadmin/package-history-details"
        } else if(this.pathUrl.includes("/superadmin/chatbot-view")){
          this.pathUrl = "/superadmin/chatbot-view"
        } else if(this.pathUrl.includes("/superadmin/broadcast-details")){
          this.pathUrl = "/superadmin/broadcast-details"
        }  else if(this.pathUrl.includes("/superadmin/update-package") || this.pathUrl.includes("/superadmin/create-package")){
          this.pathUrl = "/superadmin/packagelist"
        } else if(this.pathUrl.includes("/superadmin/create-features") || this.pathUrl.includes("/superadmin/update-features")){
          this.pathUrl = "/superadmin/featuresList"
        } else if(this.pathUrl.includes("/tempCom/userbygroup")){
          this.pathUrl = "/tempCom/userbygroup"
        } else if(this.pathUrl.includes("/superadmin/create-faq") || this.pathUrl.includes("/superadmin/update-faq")){
          this.pathUrl = "/superadmin/faq"
        } else if(this.pathUrl.includes("/superadmin/Super-admin-reply")){
          this.pathUrl = "/superadmin/Super-admin-reply"
        } else if(this.pathUrl.includes("/superadmin/Error-log")){
          this.pathUrl = "/superadmin/Error-log"
        }
        else if(this.pathUrl.includes("/superadmin/broadcast-shedule")){
          this.pathUrl = "/superadmin/broadcast-shedule"
        } else if(this.pathUrl == ("/superadmin/user-package")){
          this.pathUrl = "/superadmin/user-package"
        }else if(this.pathUrl == ("/superadmin/user/newUser") || this.pathUrl == ("/superadmin/user/cureentMonthUser") || this.pathUrl == ("/superadmin/user/activeUser") || this.pathUrl == ("/superadmin/user/deactiveUser") || this.pathUrl == ("/superadmin/user/totalUser") || this.pathUrl == ("/superadmin/user") || this.pathUrl == ("/superadmin/features-send")){
          this.pathUrl = "/superadmin/user"
        }else if(this.pathUrl == ("/superadmin/expire-token")){
          this.pathUrl = "/superadmin/expire-token"
        } else if(this.pathUrl.includes("/superadmin/upload-version-detail")){
          this.pathUrl = "/superadmin/upload-version-detail"
        } else if(this.pathUrl.includes("/superadmin/update-version")){
          this.pathUrl = "/superadmin/update-version"
        } else if(this.pathUrl.includes("/superadmin/update-email-container")){
          this.pathUrl = "/superadmin/update-email-container"
        }
      });
    }

    this.urlVal = this.location.path();
    if(this.urlVal){
      let checkRedirect :any = this.location.getState()
      
      if(checkRedirect && checkRedirect.returnRedirectBroadcastInfo != '' && checkRedirect.returnRedirectBroadcastInfo != undefined && checkRedirect.returnRedirectBroadcastInfo != null){
        this.returnRedirectBroadcastInfo = checkRedirect.returnRedirectBroadcastInfo
      }else {
        this.returnRedirectBroadcastInfo = false
      }
      if(checkRedirect && checkRedirect.redirectUserPackage != '' && checkRedirect.redirectUserPackage != undefined && checkRedirect.redirectUserPackage != null){
        this.returnRedirectUserPackage = checkRedirect.redirectUserPackage
      }else {
        this.returnRedirectUserPackage = false
      }
      if(checkRedirect && checkRedirect.redirectToExpireToken != '' && checkRedirect.redirectToExpireToken != undefined && checkRedirect.redirectToExpireToken != null){
        this.returnRedirectToExpireToken = checkRedirect.redirectToExpireToken
      }else{
        this.returnRedirectToExpireToken = false
      }
      if(checkRedirect && checkRedirect.redirectToSendFeatureList != '' && checkRedirect.redirectToSendFeatureList != undefined && checkRedirect.redirectToSendFeatureList != null){
        this.returnRedirectToSendFeatureList = checkRedirect.redirectToSendFeatureList
      }else{
        this.returnRedirectToSendFeatureList = false
      }
      if(checkRedirect && checkRedirect.redirectToAdminQuery != '' && checkRedirect.redirectToAdminQuery != undefined && checkRedirect.redirectToAdminQuery != null){
        this.returnRedirectToAdminQuery = checkRedirect.redirectToAdminQuery
      }else{
        this.returnRedirectToAdminQuery = false
      } 
      if(checkRedirect && checkRedirect.redirectToPaymentFailure != '' && checkRedirect.redirectToPaymentFailure != undefined && checkRedirect.redirectToPaymentFailure != null){
        this.returnRedirectToPaymentFailure = checkRedirect.redirectToPaymentFailure
      }else{
        this.returnRedirectToPaymentFailure = false
      }
      if(checkRedirect && checkRedirect.redirectToPackageExpireUser != '' && checkRedirect.redirectToPackageExpireUser != undefined && checkRedirect.redirectToPackageExpireUser != null){
        this.returnRedirectToPackageExpireUser = checkRedirect.redirectToPackageExpireUser
      }else{
        this.returnRedirectToPackageExpireUser = false
      }
      
      this.pathUrl =this.urlVal      
      //const isSubstringPresent = this.pathUrl.includes("/superadmin/business-user-detail");
      if(this.pathUrl.includes("/superadmin/business-user-detail")){
        this.pathUrl = "/superadmin/business-user-detail"
      } else if(this.pathUrl.includes("/superadmin/package-history-details")){
        this.pathUrl = "/superadmin/package-history-details"
      } else if(this.pathUrl.includes("/superadmin/chatbot-view")){
        this.pathUrl = "/superadmin/chatbot-view"
      } else if(this.pathUrl.includes("/superadmin/broadcast-details")){
        this.pathUrl = "/superadmin/broadcast-details"
      }  else if(this.pathUrl.includes("/superadmin/update-package") || this.pathUrl.includes("/superadmin/create-package")){
        this.pathUrl = "/superadmin/packagelist"
      } else if(this.pathUrl.includes("/superadmin/create-features") || this.pathUrl.includes("/superadmin/update-features")){
        this.pathUrl = "/superadmin/featuresList"
      } else if(this.pathUrl.includes("/tempCom/userbygroup")){
        this.pathUrl = "/tempCom/userbygroup"
      } else if(this.pathUrl.includes("/superadmin/create-faq") || this.pathUrl.includes("/superadmin/update-faq")){
        this.pathUrl = "/superadmin/faq"
      } else if(this.pathUrl.includes("/superadmin/Super-admin-reply")){
        this.pathUrl = "/superadmin/Super-admin-reply"

      } else if(this.pathUrl.includes("/superadmin/Error-log")){
        this.pathUrl = "/superadmin/Error-log"

      }else if(this.pathUrl.includes("/superadmin/broadcast-shedule")){
        this.pathUrl = "/superadmin/broadcast-shedule"
      }
      else if(this.pathUrl == ("/superadmin/user-package")){
        this.pathUrl = "/superadmin/user-package"
      }else if(this.pathUrl == ("/superadmin/user/newUser") || this.pathUrl  == ("/superadmin/user/cureentMonthUser") || this.pathUrl == ("/superadmin/user/activeUser") || this.pathUrl == ("/superadmin/user/deactiveUser") || this.pathUrl == ("/superadmin/user/totalUser") || this.pathUrl == ("/superadmin/user") || this.pathUrl == ("/superadmin/features-send")){
        this.pathUrl = "/superadmin/user"
      } else if(this.pathUrl == ("/superadmin/payment-failure")){
        this.pathUrl = "/superadmin/payment-failure"
      }else if(this.pathUrl.includes("/superadmin/expire-token")){
        this.pathUrl = "/superadmin/expire-token"
      } else if(this.pathUrl.includes("/superadmin/upload-version-detail")){
        this.pathUrl = "/superadmin/upload-version-detail"
      }else if(this.pathUrl.includes("/superadmin/update-version")){
        this.pathUrl = "/superadmin/update-version"
      }else if(this.pathUrl.includes("/superadmin/update-email-container")){
        this.pathUrl = "/superadmin/update-email-container"
      }
    }
  }
  removePaginationCondition() {
    localStorage.removeItem('PageNo');
  }
}
